import { getItem, getItems, onItem, onItems, setItem, deleteItem, deleteItems, onItemChange } from './db';
import dino from './media/dino.jpeg';

const notSupported = _ => {
  throw new Error('This feature is not supported on local(offline) workspace.');
}

const notSupportedAsync = async () => notSupported()

class LocalProvider {
  isOffline = true;

  fetchPath = (path) => getItem({ storeName: 'paths', id: path });
  checkAvailable = async (path) => !(await this.fetchPath(path));

  onDomain = (id, cb, ecb) => onItem({ storeName: 'domains', id }, cb, ecb);
  updateDomain = ({ domainId, data }) => setItem({
    value: Object.assign({}, data, { id: domainId }),
    update: true,
    storeName: 'domains'
  });

  fetchDomain = ({ domainId }) => getItem({ storeName: 'domains', id: domainId });

  // ****** Folder Management
  
  deletePath = (pathId) => deleteItem({ storeName: 'paths', id: pathId });
    
  createChild = async ({ slug, title, parentId, storeName }) => {
    const
      domainId = 'local',
      parent = await getItem({ storeName: 'folders', id: parentId }),
      path = `${parent.path}\\${slug}`,
      value = {
        parentId, domainId, slug, path, title, settings: {}, collaborators: [], description: '',
        thumbnails: {}, layout: { type: 'scroll', order : [] },
      },
      type = storeName === 'folders' ? 'folder' : 'article';

    if (await this.fetchPath(path))
      throw new Error('The resource already exists for the slug.')

    if (storeName === 'folders') {
      Object.assign(value, { isPublic: false, layout: { list: [] } });
    } else if (storeName === 'articles') {
      Object.assign(value, { isPublic: false, layout: { order: [] } });
    }

    const item = await setItem({ storeName, value });
    await setItem({
      storeName: 'paths',
      value: {
        id: path, type, domainId,
        folderId: storeName === 'folders' ? item.id : null,
        articleId: storeName === 'articles' ? item.id : null,
      }
    });
    parent.layout.list.unshift({ id: item.id, type });
    await setItem({
      storeName: 'folders',
      value: { id: parentId, layout: parent.layout },
      update: true
    });
    return item;
  }

  removeChild = async (parentId, childId) => {
    const parent = await getItem({ storeName: 'folders', id: parentId });
    parent.layout.list = parent.layout.list.filter(i => i.id !== childId);
    await setItem({ update: true, storeName: 'folders', value: { id: parentId, layout: parent.layout }});
  }

  updateArticlePath= async (id, path, oldPath) => {
    await deleteItem({ storeName: 'paths', id: oldPath });
    await setItem({ storeName: 'paths', value: {
      id: path, articleId: id, domainId: 'local', folderId: null, type: 'article'
    }, update: true });
    await setItem({ storeName: 'articles', value: { id, path }, update: true });
  }

  updateFolderPath = async (id, path, oldPath) => {
    let domainId = 'local';
    await deleteItem({ storeName: 'paths', id: oldPath });
    await setItem({ storeName: 'paths', value: { id: path, folderId: id, domainId, type: 'folder' }, update: true });
    await setItem({ storeName: 'folders', value: { id, path }, update: true });

    for (let item of await this.fetchChildrenFolders({ domainId, folderId: id })) {
      this.updateFolderPath(item.id, `${path}\\${item.slug}`, item.path);
    };
    for (let item of await this.fetchChildrenArticles({ domainId, folderId: id })) {
      this.updateArticlePath(item.id, `${path}\\${item.slug}`, item.path);
    };
  }
  
  renameSlug = async ({ slug, articleId, folderId }) => {
    const
      storeName = articleId ? 'articles': 'folders',
      id = articleId || folderId,
      item = await getItem({ storeName, id }),
      parent = await getItem({ storeName: 'folders', id: item.parentId }),
      path = `${parent.path}\\${slug}`;

    if (await this.fetchPath(path))
      throw new Error('Slug already in use.');

    await setItem({ storeName, value: { id, slug }, update: true });
    if (articleId)
      await this.updateArticlePath(id, path, item.path);
    else
      await this.updateFolderPath(id, path, item.path);
  };

  moveToFolder = async ({ itemType, itemId, newParentId }) => {
    const
      storeName = itemType + 's', // folders or articles
      item = await getItem({ storeName: storeName, id: itemId }),
      oldParent = await getItem({ storeName: 'folders', id: item.parentId }),
      newParent = await getItem({ storeName: 'folders', id: newParentId }),
      newPath = `${newParent.path}\\${item.slug}`;

    if (await this.fetchPath(newPath))
      throw new Error('A resource with same slug already exists in the folder.');
    
    oldParent.layout.list = oldParent.layout.list.filter(i => i.id !== itemId);
    newParent.layout.list.unshift({ id: itemId, type: itemType });
    item.parentId = newParentId;
    await setItem({ storeName: 'folders', value: oldParent });
    await setItem({ storeName: 'folders', value: newParent });
    await setItem({ storeName: storeName, value: item });
    (
      itemType === 'article' ?  this.updateArticlePath : this.updateFolderPath
    )(itemId, newPath, item.path );
  };

  // ****** Folder
  onFolder = (domainId, folderId, cb, ecb) => onItem({ storeName: 'folders', id: folderId }, cb, ecb);

  fetchFolder = ({ domainId, folderId }) => getItem({ storeName: 'folders', id: folderId });

  onChildrenFolders = (domainId, folderId, cb, ecb) => onItems({
    storeName: 'folders', filter: v => v.parentId === folderId
  }, cb, ecb);
  fetchChildrenFolders = ({ domainId, folderId }) => getItems({
    storeName: 'folders', filter: v => v.parentId === folderId
  });

  onChildrenArticles = (domainId, folderId, cb, ecb) => onItems({
    storeName: 'articles', filter: v => v.parentId === folderId
  }, cb, ecb);
  fetchChildrenArticles = ({ domainId, folderId }) => getItems({
    storeName: 'articles', filter: v => v.parentId === folderId
  });

  createFolder = ({ slug, title, domainId, parentId }) => this.createChild({
        slug, title, domainId, parentId, storeName: 'folders'
  });

  deleteFolder = async ({ domainId, folderId }) => {
    const folder = await getItem({ storeName: 'folders', id: folderId });
    await this.removeChild(folder.parentId, folderId);
    await this.deletePath(folder.path);
    await deleteItem({ storeName: 'folders', id: folderId });
  }

  updateFolder = ({ domainId, folderId, data }) => setItem({
    value: Object.assign({}, data, { id: folderId }),
    update: true,
    storeName: 'folders'
  });


  // ****** Article and Elements  
  onArticle = (domainId, articleId, cb, ecb) => onItem({ storeName: 'articles', id: articleId }, cb, ecb);

  createArticle = async ({ domainId, parentId, slug, title, layoutType }) => {
    const
      articleData = await this.createChild({
        slug, title, domainId, parentId, storeName: 'articles'
      }),
      articleId = articleData.id;

    await setItem({
      storeName: 'articles',
      value: {
        id: articleId,
        description: '',
        settings: {},
        thumbnails: {},
        layout: { type: (layoutType || 'scroll'), order: [], grid: { config: {}, elements: {} } },
      },
      update: true
    });
    return ({ data: { articleId } });
  }

  deleteArticle = async ({ domainId, articleId}) => {
    const article = await getItem({ storeName: 'articles', id: articleId });
    await this.removeChild(article.parentId, articleId);
    await this.deletePath(article.path);
    await deleteItems({ storeName: 'elements', filter: value => value.articleId === articleId });
    await deleteItems({ storeName: 'uploads', filter: value => value.articleId === articleId });
    // todo: versions files
  }

  updateArticle = ({ domainId, articleId, data }) => setItem({
    value: Object.assign({}, data, { id: articleId }),
    update: true,
    storeName: 'articles'
  });

  onArticleElementChange = (domainId, articleId, cb, ecb) => onItemChange({
    storeName: 'elements',
    filter: v => v.articleId === articleId
  }, cb, ecb);

  fetchArticle = ({ domainId, articleId }) => getItem({
    storeName: 'articles',
    id: articleId
  });

  fetchArticleElements = ({ domainId, articleId }) => getItems({
    storeName: 'elements',
    filter: v => v.articleId === articleId
  })

  createElement = async ({ domainId, articleId, data }) => setItem({
    storeName: 'elements',
    value: {...data, articleId, domainId }
  });

  updateElement = ({ domainId, articleId, elementId, data }) => setItem({
    storeName: 'elements',
    value: {...data, articleId, id: elementId },
    update: true
  });
  
  deleteElement = async ({ domainId, articleId, elementId }) => deleteItem({
    storeName: 'elements',
    id: elementId
  })

  onArticleUploads = (domainId, articleId, cb, ecb) => onItems({
    storeName: 'uploads',
    filter: v => v.articleId === articleId
  }, cb, ecb);


  getDownloadURL = async (path, forMachine) => {
    if (path === 'dino.image')
      return dino;
    const value = await getItem({ storeName: 'storage', id: path });
    return value && value.fileData;
  };

  saveFile = ({ id, file }) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', async (event) => {
        const fileData = event.target.result;
        setItem({ storeName: 'storage', value: { id, fileData } })
          .then(resolve).catch(reject);
      });
      reader.addEventListener('error', reject);
      reader.readAsDataURL(file);
    })
  }

  uploadArticleFile = ({ articleId, userId, filename, file}, progress, error, done) => {
    const
      { type, size } = file,
      now = (new Date()),
      uid = now.getTime().toString(36),
      id = `article-${articleId}-file-${filename}-${uid}`;

    setItem({
      storeName: 'uploads',
      value: {
        id, articleId, userId,
        filename, name: id, contentType: type,
        size, createdAt: now.toISOString(), deleted: false        
      }
    })
      .catch(error)
      .then(() => this.saveFile({ file, id }))
      .then(done).catch(error);
  };

  deleteUpload = async ({ uploadId, articleId }) => {
    await deleteItem({ storeName: 'uploads', id: uploadId });
    await deleteItem({ storeName: 'storage', id: uploadId });
  };

  getUploadUsedBy = async ({ uploadId, articleId }) => {
    return [];
  };


  uploadImage = notSupportedAsync;
  removeThumbnail = notSupportedAsync;
  getDomainAccessList = notSupportedAsync;
  addDomainAccess = notSupportedAsync;
  getDomainUserAccess = notSupportedAsync;
  deleteDomainUserAccess = notSupportedAsync;
  updateDomainUserRole = notSupportedAsync;
  getUserId = notSupportedAsync;
  getUserName = notSupportedAsync;
  onArticleVersions = notSupportedAsync;
  createVersion = notSupportedAsync;
  fetchVersion = notSupportedAsync;
  fetchVersionElements = notSupportedAsync;
  updateVersion = notSupportedAsync;
  deleteVersion = notSupportedAsync;
  revertToVersion = notSupportedAsync;
  publishVersion = notSupportedAsync;
  unpublishArticle = notSupportedAsync;
  onArticleAccesses = notSupportedAsync;
  updateArticleAccess = notSupportedAsync;
  deleteArticleAccess = notSupportedAsync;
  fetchTally = notSupportedAsync;
  fetchDomainPlans = notSupportedAsync;

  fetchComments = notSupportedAsync;
  fetCommentLikes = notSupportedAsync;
  postComment = notSupportedAsync;
  deleteComment = notSupportedAsync;
  likeCommentCall = notSupportedAsync;
  reportComment = notSupportedAsync;
  fetchUserInfo = notSupportedAsync;
}

export default LocalProvider;