import BaseController from 'lib/BaseController';
import { fromJS } from 'immutable';

class Panels extends BaseController {
  constructor() {
    super();
    this.state = this.fetchState();;
  }

  hasPanel(id, key) {
    return this.state.get(key).indexOf(id) !== -1;
  }

  addPanel(panel, key) {
    this.setState({ [key]: this.state.get(key).push(panel) });
  }

  removePanel(id, key) {
    if (this.hasPanel(id, key)) {
      this.setState({ [key]: this.state.get(key).filter(_id => _id !== id) });
    }
  }

  saveInState(l, val) {
    this.setInState(l, val);
    this.saveState();
  }

  storageKey = 'article-panel-state-v1';
  initialState = {
    hudLayout: '0',
    // groups: {
    //   left: ['folder', 'overview'],
    //   right: ['inspector'],
    //   floating: [],
    // },
    // positions: {},
    // selection: { left: 0, right: 0 },
    expand: { left: null, right: null }
  };

  setInState(keys, v) {
    this.saveState();
    console.log('saving panel data');
    return super.setInState(keys, v);
  }

  fetchState() {
    const stored = window.localStorage.getItem(this.storageKey);
    try { if (stored) return fromJS(JSON.parse(stored)); }
    catch(e) { console.error('Invalid Panel Data'); }
    return fromJS(this.initialState);
  }

  saveState() {
    window.localStorage.setItem(this.storageKey, JSON.stringify(this.state.toJS()));
  }

  move(id, from, to) {
    let
      groups = this.state.get('groups'),
      positions = this.state.get('positions');

    console.log('moving panels', from, to);
    groups = groups.set(from, groups.get(from).filter(_id => _id !== id));
    groups = groups.set(to, groups.get(to).push(id));
    if (from === 'floating')
      positions = positions.remove(id);
    if (to === 'floating')
      positions = positions.set(id, fromJS({ x: 10, y: 10, width: 280, height: 350 }));
    console.log(groups.toJS());
    this.setState({ groups, positions });
  }

  getPosition(id) {
    return this.state.getIn(['positions', id]) || fromJS({ x: 10, y: 210, width: 280, height: 350 });
  }

  setPosition(id, position) {
    this.setInState(['positions', id], position);
  }
}

export default Panels;