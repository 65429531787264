import { fields } from '../Policies/settings';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import RichTextInput from 'modules/elements/lib/sidebar/inputs/RichTextInput';
import './style.css';


function Inspector({ controller }) {
  const {
    setSettings,
    getSettingsData,
    getSettingsValue,
    getSettingsValueOnNull } = controller,

    buildInputsProps = {
      fields: fields,
      getData: getSettingsData,
      getValue: getSettingsValue,
      setData: setSettings,
      getValueOnNull: getSettingsValueOnNull
    }


  return (
    <div className="inspector gallery-inspector">      
      <RichTextInput
        caption="Caption"
        value={getSettingsValue('caption')}
        onClick={_ => controller.editCaption()} />

      <BuildInputs
        layout={[
          [ ['captionAlignment'] ],
          [
            ['alignment'],
            // ['maxWidth', ],
            ['hasBorder'],
            ['backgroundColor']
          ]
        ]}
        {...buildInputsProps} />


    </div>
  );
}

export default Inspector;