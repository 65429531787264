import { useControllerState } from 'lib/BaseController';
import elementLibs from 'modules/elements/all';
import Margins from 'modules/layout/View/Grid/Outlines/Margins';
import clsx from 'clsx';
// import Tiles from 'modules/layout/View/Grid/Outlines/Tiles';

export default function Grid({ article, panelLeft, panelRight }) {
  const
    { layout } = article,
    { grid } = layout;

  return (
    <GridPage {...{ grid, panelLeft, panelRight }}>
      { layout.order.map((id, index) => article.elementControllers[id] && (
        <GridElement
          key={id}
          id={id}
          element={article.elementControllers[id]}
          grid={grid} />
      ))}
      <Margins grid={grid} isResizable={false} />
    </GridPage>
  );
}

function GridPage({ children, grid, panelRight, panelLeft }) {
  const
    { pageRect } = grid,

    { width, height } = pageRect;

  return (
    <div className="text-center min-w-full">
      <div
        className={clsx(
          'text-left px-12 pt-24 pb-24 transition-[padding] inline-block',
          panelLeft && 'md:pl-[22rem]',
          panelRight === 'detail' && 'md:pr-[42rem]',
          panelRight === 'comments' && 'md:pr-[27rem]'
        )}>
        <div
          className="m-auto relative"
          style={{ width, height }}>
          {/*<Tiles grid={grid} />*/}
          { children }
        </div>
      </div>
    </div>
  )
}

function GridElement({ id, element, grid }) {
  const
    { x, y, w, h } = grid.getGridElement(id).toJS(),
    { cellSize, cellGap } = grid,
    width = (w || 1) * (cellSize.width + cellGap.x) - cellGap.x,
    height = (h || 1) * (cellSize.height + cellGap.y) - cellGap.y,
    left = (x || 0) * (cellSize.width + cellGap.x),
    top = (y || 0) * (cellSize.height + cellGap.y);

  return (
    <div className="absolute" style={{ width, height, left, top }}>
      <Element element={element} />
    </div>
  );
}

function Element({ element }) {
  useControllerState(element);
  const lib = elementLibs[element.type];
  return (<lib.Viewer controller={element.controller} isGrid={true} />)
}