import ColDraggers from './ColDraggers';
import Cell from './Cell';
import clsx from 'clsx';
import Menu from './Menu';

import './style.css';

function Editor({ controller, isActive, isGrid }) {
  const
    { getSettingsValue, state, data, isReadOnly } = controller,
    { rows, columns } = data,
    style = {},
    focused = state.get('isFocused');

  return (
    <div
      onClick={controller.activate}
      className={clsx(
        'nb-table max-w-full overflow-auto text-sm group/table relative bg-paper',
        'style-' + getSettingsValue('styling'),
        (focused ? ' focused' : ''),
        isGrid && 'h-full'
      )}>
      <div
        className="table user-select-none relative"
        style={style}
        ref={ref => controller.setTableRef(ref)}>
        <input
          style={{'opacity': 0, position: 'absolute', pointerEvents: 'none'}}
          ref={ref => controller.setInputRef(ref)}
          onFocus={e => controller.handleFocus(e)}
          onBlur={e => controller.handleBlur(e)}
          onKeyDown={controller.keyManager.handleInputKeyDown}
          onKeyUp={controller.keyManager.handleInputKeyUp} />

        <div className="rows">
          { Array(rows.size + 1).fill(0).map((row, i) => (
            <div className={'row flex' + (i === 0 ? ' table-head font-bold' : '')} style={{ }} key={i}>
              { columns.map((column, j) => {
                return (
                  <Cell
                    tableIsActive={isActive}
                    key={i + '.' + j}
                    i={i - 1} j={j}
                    controller={controller} />)
              })}
            </div>
          )) }
        </div>

        {!isReadOnly && (
          <ColDraggers
            widths={columns.map(c => c.get('width'))}
            onChange={data.handleColWidthChange} />
        )}
      </div>

      { !isReadOnly && (<Menu controller={controller} />) }
    </div>
  );
}

export default Editor;