import Scroll from './Scroll';
import Grid from './Grid';

export default function Page(props) {
  const { layout, } = props;
  
  return (
    <>
      { layout.type === 'grid' ? (<Grid {...props} />) : (<Scroll {...props} />) }
    </>
  );
}