import { useState } from 'react';
import clsx from 'clsx';
import MenuBuilder from 'lib/components/MenuBuilder'
import {
  CheckIcon, PlusIcon, TrashIcon,
  CaretLeftIcon, CaretRightIcon, CaretDownIcon, CaretUpIcon, ResetIcon } from '@radix-ui/react-icons';

export default function Menu({controller}) {
  const
    { selection, data } = controller,
    { history } = data,
    { si, sj } = selection,
    column = data.getCell(-1, sj),
    isSortable = column.get('isSortable'),
    options = [],
    [open, setOpen] = useState(false);

  options.push(
    ['Insert Row', PlusIcon, () => { data.insertRow(1); }],
    ['Insert Column', PlusIcon, () => { data.insertCol(1); }],
    [],
    ['Is Sortable', isSortable ? CheckIcon : '', () => { data.setCell(-1, sj, 'isSortable', !isSortable); }],
    ['Sort Asecending', '', () => { data.sort(sj); } ],
    ['Sort Descending', '', () => { data.sort(sj, true); }],
    [],
    ['Move Column Left', CaretLeftIcon, () => { data.moveCol(sj, -1); selection.moveSelection(0, -1); }],
    ['Move Column Right', CaretRightIcon, () => { data.moveCol(sj, 1); selection.moveSelection(0, 1); }],
    ['Move Row Up', CaretUpIcon, () => { data.moveRow(si, -1); selection.moveSelection(-1, 0); }],
    ['Move Row Down', CaretDownIcon, () => { data.moveRow(si, 1); selection.moveSelection(1, 0); }],
    [],
    ['Delete Column', TrashIcon, () => { data.deleteColPrompt(); }],
    ['Delete Row', TrashIcon, () => { data.deleteRowPrompt(); }],
    [],
    ['Undo', ResetIcon, () => { data.undo(); }, { isDisabled: history.index <= 0 }],
    ['Redo', '', () => { data.redo(); }, { isDisabled: history.index >= history.history.size - 1 }],
  );

  return (
    <div
      className={clsx(
        'absolute top-1 right-1 bg-paper px-1 rounded-sm',
        open ? '' : 'hidden group-hover/table:block'
      )}>
      <MenuBuilder
        options={options}
        isOpen={open}
        onOpenChange={setOpen}
        tight={true} />
    </div>
  );
}