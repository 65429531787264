import Logo from 'lib/Logo';
import LogoButton from './LogoButton';
import Titles from './Titles';
import PageButtons from './PageButtons';
import ArticleButtons from './ArticleButtons';
import MachineButton from './MachineButton';
import PanelButtons from './PanelButtons';
import MenuButton from './MenuButton';
import { Break } from './res/components';



export function BarHorizontal({ editor }) {
  return (
    <div className="fixed top-0 left-0 w-full flex z-30 pt-3 pb-3 px-6 bg-gradient-to-b from-paper via-paper via-75%">
      <div className="flex rounded-sm pl-4 pr-2 py-2 gap-2">
        <LeftGroup editor={editor} />
      </div>
      <div className="flex-grow"></div>
      <div className="flex items-center py-1 px-2 gap-1 rounded-sm">
        <RightGroup editor={editor} verticle={false} />
      </div>
    </div>
  )
}

export function BarVertical({ editor }) {
  return (
    <div className="px-2 py-4 flex flex-col h-full items-center">
      <div className="">
        <LogoButton editor={editor} showChevron={false} showTitle={true} placement="end" wide={true}>
          <Logo className="w-8 h-8 inline-block" />
        </LogoButton>
      </div>

      <div className="flex-grow"></div>

      <div className="flex flex-col items-center bg-paper py-1 px-2 gap-1 border border-rim rounded-sm">
        <RightGroup editor={editor} verticle={true} />
      </div>
    </div>
  )
}

export function LeftGroup({ editor }) {
  return (
    <>
      <div className="flex-shrink-0">
        <LogoButton editor={editor} />
      </div>
      <Titles editor={editor} />    
    </>
  )
}

export function RightGroup({ editor, verticle }) {
  return (
    <>
      <PageButtons editor={editor} />
      <Break verticle={verticle} />
      <PanelButtons panels={editor.panels} />
      <Break verticle={verticle} />
      <ArticleButtons editor={editor} />
      <MachineButton machine={editor.app.machine} editor={editor} withBreak={true} verticle={verticle} />
      <Break verticle={verticle} />
      <MenuButton editor={editor} />
    </>
  )
}

