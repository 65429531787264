import BarFiles from '../layouts/components/BarFiles';
import MenuButton from '../layouts/components/MenuButton';
import ModeSelection from '../layouts/components/ModeSelection';
import { Button } from 'react-aria-components';
import { Cross1Icon } from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function IDEFileBar({ controller, isGrid, isActive, isDark, fileControllers }) {  
  const fileController = fileControllers.get(controller.currentIndex);

  return (
    <div
      className={clsx('flex border-b border-rim bg-wax px-2 gap-2 items-center ',)}>

      <div className="px-2 text-sm">
        <ModeSelection controller={controller} fileController={fileController} />
      </div>

      <div className="overflow-auto no-scrollbar flex-grow">
        <BarFiles
          controller={controller}
          fileControllers={fileControllers}
          isGrid={isGrid}
          isActive={isActive}
          isDark={isDark}
          buttonsClassName="gap-2"
          buttonClassName={({ isActive }) => clsx(
            'px-2 py-2.5 font-mono text-xs',
            isActive && 'border-b border-pencil font-bold'
          )} />
      </div>
      <MenuButton
        controller={controller}
        isGrid={isGrid} className="px-1" />

      <Button onPress={() => controller.expandDetail(false)} className="text-pen p-2">
        <Cross1Icon />
      </Button>

    </div>
  )
}