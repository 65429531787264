import BarFiles from './components/BarFiles';
import MenuButton from './components/MenuButton';
import ModeSelection from './components/ModeSelection';
import clsx from 'clsx';


export default function TopFileBar({ controller, isGrid, isActive, isDark, fileControllers }) {
  const fileController = fileControllers.get(controller.currentIndex);
  
  return (
    <div
      className={clsx(
        'flex items-center border-b border-rim bg-wax gap-1 px-2',
        isDark && 'bg-wax'
      )}>
      <div className="">
        <ModeSelection controller={controller} fileController={fileController} />
      </div>
      <div className="flex-grow overflow-auto no-scrollbar px-1">
        <BarFiles
          controller={controller}
          fileControllers={fileControllers}
          isGrid={isGrid}
          isActive={isActive}
          isDark={isDark}
          buttonsClassName="gap-2"
          buttonClassName={({ isActive }) => clsx(
            'py-2 px-2',
            isActive && 'border-b border-pencil font-bold'
          )} />
      </div>
      <MenuButton
        className="p-1"
        controller={controller}
        isGrid={isGrid} />
    </div>
  )
}