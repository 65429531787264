import { FileIcon, CheckIcon, ExclamationTriangleIcon, TrashIcon, EraserIcon } from '@radix-ui/react-icons';
import { useState, useEffect } from 'react';
import Filename from 'modules/elements/all/File/components/Filename';
import Loader from 'lib/staticPages/Loader';
import { Button } from 'react-aria-components';
import clsx from 'clsx';
import Tooltipped from 'lib/inputs/Tooltipped';


export default function MachineFiles({ controller }) {
  const { state } = controller;
  const [children, setChildren] = useState(controller.getChildren());
  useEffect(() => controller.onChildren(() => setChildren(controller.getChildren)), [controller]);

  return !state.get('processIsActive') && children.length > 0 && (
    <div className="p-2 py-4 pb-6">
      <div className="underline mb-2">Files Pulled on Complete</div>

      { children.map((child, i) =>
        <Child
          key={child.elementId}
          child={child}
          status={state.getIn(['fetchStatus', child.elementId])}
          errorMessage={state.getIn(['fetchStatus', 'error' + child.elementId])}
          isReadOnly={controller.isReadOnly} />
      )}
    </div>
  )
}

function Child({ child, status, errorMessage, isReadOnly }) {
  const [state, setState] = useState(child.state);
  useEffect(() => child.onStateChange(e => setState(child.state), [child]));
  const { elementId } = child;

  return (
    <div key={elementId} className="flex gap-2 items-center group/machine-item">
      <div
        className={clsx(
          state.get('content') === null && 'opacity-60',
          'flex gap-2 items-center'
        )}>
        <FileIcon className="inline" />
        <Filename controller={child} />
      </div>

      { status === 'error' && (
        <span className="text-error">
          <ExclamationTriangleIcon className="inline" />&nbsp;
          { errorMessage }
        </span>
      ) }

      { (status === 'loaded' || !status) && state.get('content') !== null && (
        <CheckIcon />
      ) }

      {!isReadOnly && (
        <div className="opacity-0 group-hover/machine-item:opacity-100 flex gap-4 ml-4">
          <Tooltipped tooltip="Clear Content">
            <Button
              className={({isDisabled}) => clsx('font-bold underline', isDisabled && 'opacity-60')}
              isDisabled={state.get('content') === null}
              onPress={() => child.setState({ content: null })}>
              <EraserIcon />
            </Button>
          </Tooltipped>
          <Tooltipped tooltip="Delete">
            <Button
              className="font-bold underline"
              onPress={child.deletePrompt}>
              <TrashIcon />
            </Button>
          </Tooltipped>
        </div>
      )}

      { status === 'loading' && (<Loader />)}
    </div>
  );
}