import { memo } from 'react';
import { useControllerState } from 'lib/BaseController';

export default function DetailEditor({ editor, detailId }) {
  const
    ac = editor.currentArticleController,
    element = ac && ac.elements[detailId];
  return element && (<MemoizedElement editor={editor} element={element} />)
}

export function Element({ editor, element }) {
  useControllerState(element);
  return (
    <element.lib.Editor
      controller={element.controller}
      isActive={false}
      isGrid={false}
      isDetail={true} />)
}
const MemoizedElement = memo(Element);