import { Button } from 'react-aria-components';
import { RowsIcon } from '@radix-ui/react-icons';
import Folder from './components/Folder';

export default function LeftPanel({ viewer }) {
  const panelLeft = viewer.state.get('panelLeft');
  
  return panelLeft && viewer.currentArticle && (
    <div className="fixed top-0 left-0 bottom-0 w-[18rem] border-r border-rim bg-paper py-2">
      <div className="flex px-3 py-2 items-start">
        <div className="flex-grow text-lg px-2">
          {viewer.workspace.domain.title}
        </div>

        <Button
          className="p-1 outline-none"
          onPress={() => viewer.setPanelLeft('')}>
          <RowsIcon />
        </Button>
      </div>

      <div className="border-t p-4 border-rim">
        <Folder viewer={viewer} />        
      </div>
    </div>
  )
}