import { useControllerState } from 'lib/BaseController';
import { Buttons, Button } from 'lib/inputs/buttons';
import ui from 'lib/popup/components/ui';
import CodeMirror from '@uiw/react-codemirror';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import allThemes from 'lib/codemirror/themes';
import themes from 'modules/theme/defaults/theme';

import './style.css';


function Popup({ popup }) {
  const
    state = useControllerState(popup),
    extensions = [],
    languageExtension = loadLanguage('json');

  if (languageExtension)
    extensions.push(languageExtension);

  return (
    <ui.Popup popup={popup} className="code-editor-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>{popup.args.title}</ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.handleSubmit(); }}>
            <div className="border border-rim my-4">
              <CodeMirror
                theme={allThemes[popup.args.colorScheme === 'dark' ?  themes.codeThemeDark :  themes.codeThemeLight]}
                value={state.get('code')}
                extensions={extensions}
                height={typeof popup.args.height === 'undefined' ? `200px` : popup.args.height}
                basicSetup={{                
                  highlightActiveLine: false,
                  foldGutter: false,
                }}
                onChange={(value, viewUpdate) => { popup.setState({ code: value }); }}
                autoFocus={true} />
            </div>

            <ui.Alert />
            
            <Buttons className="text-right">
              <Button type="submit" isDisabled={state.get('loading') || state.get('invalid')}>
                Save
              </Button>

              <Button onPress={e => { popup.close() }}>
                Cancel
              </Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

export default Popup;