import { Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useControllerState } from 'lib/BaseController';
import { AddUnderlay, AddHoverBar } from './addOverlays';
import './style.css';
import { DragHandleDots2Icon } from '@radix-ui/react-icons';

import clsx from 'clsx';


function Layout({ titleElement, emptyElement, renderedElements, layout, selection, hidden }) {
  const
    { activeElementId, isPageFocused, selectedElements } = selection,
    { scroll } = layout,
    state = useControllerState(scroll),
    adding = state.get('adding');

  const handleMouseDownOnPage = e => {
    if (e.target === e.currentTarget) {
      selection.focusPage();
      e.preventDefault();
    }
  }

  const onDragEnd = ({ source, destination }) => {
    if (destination && source.index !== destination.index) {
      layout.moveElementIndex(source.index, destination.index);
    }
  }

  // const
  //   { pointSize, themeData } = layout,
  //   pageMargin = themeData.getValue('pageMargin'),
  //   pagePaddingStyle = {
  //     paddingLeft: pageMargin.left * pointSize,
  //     paddingRight: pageMargin.right * pointSize,
  //     paddingTop: pageMargin.top * pointSize,
  //     paddingBottom: pageMargin.bottom * pointSize
  //   };

  return (
    <div
      className={clsx(
        hidden && 'hidden',
        'px-12 pt-12 pb-16 w-full h-full overflow-auto transition-[padding]',
        'md:group-[.has-sidebar-sm]:pr-[21rem]', // 18 + 3
        'md:group-[.has-sidebar-lg]:pr-[43rem]' // 40 + 3
      )}
      onMouseDown={handleMouseDownOnPage}>
      <div
        className={clsx(
          'relative page-max-width mx-auto bg-paper2',
          // 'border border-marker border-opacity-10'
        )}
        // style={pagePaddingStyle}
        onMouseDown={handleMouseDownOnPage}>
        <div ref={ref => layout.setElementContainerRef('titlebar', ref)}>
          <TitleElement
            isActive={activeElementId === 'titlebar'}
            isSelected={isPageFocused && selectedElements.has('titlebar')}
            titleElement={titleElement} />
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                { layout.order.map((id, index) => renderedElements[id] && (
                  <Fragment key={id}>
                    <DraggableElement
                      id={id}
                      index={index}
                      isLast={index === layout.order.size - 1}
                      containerRef={ref => layout.setElementContainerRef(id, ref)}
                      isActive={(activeElementId === id)}
                      isSelected={isPageFocused && selectedElements.has(id)}
                      selectMe={() => selection.setActiveElementId(id)}
                      renderedElement={renderedElements[id]}
                      layout={layout} />
                  </Fragment>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <AddHoverBar layout={layout} />
        { adding && (<AddUnderlay scroll={scroll} index={layout.order.length} />) }
      </div>
    </div>
  )
}

function TitleElement({ titleElement, isActive, isSelected }) {
  return (
    <div className="relative mb-6 pt-1">
      <Highlighter
        isActive={isActive}
        isSelected={isSelected}
        isDraggable={false}
        className="pointer-events-none" />
      {titleElement}
    </div>
  );
}

function DraggableElement({ id, index, renderedElement, isActive, isSelected, isLast, containerRef, selectMe, layout }) {
  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <div {...provided.draggableProps} style={provided.draggableProps.style}>
          <div
            className={clsx(index === 0 ? 'mb-8' : isLast ? 'mt-8' : 'my-8', 'relative')}
            ref={provided.innerRef}
            onMouseDown={selectMe}>
            <Highlighter
              isActive={isActive}
              isSelected={isSelected}
              dragHandleProps={provided.dragHandleProps}
              isDraggable={true}
              tabIndex={-1} />

            <div ref={containerRef} className="scroll-layout-element-container">
              { renderedElement }
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

function Highlighter({ dragHandleProps={}, isActive, isSelected, isDraggable }) {
  return (
    <div
      className={clsx(
        'nb-element-highlighter absolute h-full -left-10 flex gap-1',
        !isDraggable && 'pointer-events-none'
      )}
      {...dragHandleProps}>
      <DragHandleDots2Icon
        className={clsx(
          (!isDraggable || !(isActive || isSelected)) && 'opacity-0',
          isActive ? 'text-marker' : ''
        ) } />
      <div
        className={clsx(
          'h-full w-4',
          (isActive || isSelected) && 'border-l',
          isActive ? 'border-marker' : 'border-pencil3'
        )}></div>
    </div>
  )
}

export default Layout;