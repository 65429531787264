import Prompt  from 'lib/popup/popups/Prompt/Controller';
import { Normal, Sidebar  } from './images';
import { SunIcon, MoonIcon, DesktopIcon } from '@radix-ui/react-icons';

export default function openPopup(editor) {
  editor.popupManager.open(Prompt, {
    title: 'Editor Preferences',
    inputs: [
      {
        label: 'Color Mode',
        type: 'select2',
        defaultValue: editor.app.colorScheme.state.get('scheme'),
        options: [
          { key: 'light', caption: <><SunIcon className="inline mr-2" /> Light Mode </> },
          { key: 'dark', caption: <><MoonIcon className="inline mr-2" /> Dark Mode</> },
          { key: null, caption: <><DesktopIcon className="inline mr-2" /> System's Default</> },
        ]
      },
      {
        label: 'HUD Layout',
        type: 'radio',
        defaultValue: editor.panels.state.get('hudLayout'),
        autoFocus: true,
        options: [
          [
            '0',
            'Normal',
            <Preview Image={Normal} description="Default display. comes with scroll bar" />
          ],
          // [
          //   '1',
          //   'Floating',
          //   <Preview Image={Floating} description="Floating gives you a little more real estate to work with" />
          // ],
          [
            '2',
            'Sidebar',
            <Preview Image={Sidebar} description="On the side. Minimal design" />
          ]
        ]
      }
    ],
    submitLabel: 'Save',
    onSubmit: (values) => {
      const [ schemeData, hudLayout ] = values;
      editor.app.colorScheme.setSchemeData(schemeData)
      editor.panels.saveInState(['hudLayout'], hudLayout);
    }
  });
}

function Preview({ Image, description }) {
  return (
    <div className="flex-grow">
      <Image className="w-28 h-24 block mx-auto bg-wax2 px-2" />
      {/*<div className="text-sm p-2 text-center">{description}</div>*/}
    </div>
  );
}