import settingsFields from '../Policies/settingsFields';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import { Button } from 'modules/elements/lib/sidebar/inputs/buttons';
import { Block } from 'modules/elements/lib/sidebar/inputs/components';
// import Collapsible from 'modules/elements/lib/sidebar/inputs/Collapsible';
import Tabs from 'modules/elements/lib/sidebar/inputs/Tabs';

import './style.css'

function Inspector({ controller, isGrid }) {
  const
    { setSettings, getSettingsData, getSettingsValue, getSettingsValueOnNull } = controller,
    buildProps = {
      fields: settingsFields,
      getData: getSettingsData,
      getValue: getSettingsValue,
      setData: setSettings,
      getValueOnNull: getSettingsValueOnNull
    };

  return (
    <Tabs
      selectedKey={controller.state.get('inspectorTab')}
      onSelectionChange={key => controller.setState({ inspectorTab: key })}
      tabs={[
        { key: 'runtime', caption: 'Runtime', el: (<RuntimeTab {...{controller, buildProps, isGrid}} />) },
        { key: 'display', caption: 'Display', el: (<DisplayTab {...{controller, buildProps, isGrid}} />) },
      ]} />
  );
}


function RuntimeTab({ buildProps, controller }) {
  return (
    <>
      <BuildInputs
        {... buildProps }
        layout={[
          [
            ['runtime'],
            ['target'],
            ['renderOption'],
            ['classToRender'],
          ],
        ]} />

      <Block>
        <Button onPress={controller.openImportMapPopup}>
          Edit Import Map
        </Button>
      </Block>

      <BuildInputs
        {... buildProps }
        layout={[
          [],
          [ ['autorun'], ]
        ]} />      
    </>
  )
}

function DisplayTab({ buildProps, controller, isGrid }) {
  return (
    <BuildInputs
      {... buildProps }
      layout={[
        [
          ['layout'],
          ...(isGrid ? [] : (
            controller.getSettingsValue('layout') === 'fixed' ? 
              [
                ['fixedWidth', 'fixedHeight'],
                ['alignment'], // ['scaling'],
              ] :
              controller.getSettingsValue('layout') === 'dynamic' ? [
                ['autoHeight', 'height']
              ] : []
          ))
        ],
        [
          ['hasBorder'],
          ['showTarget', 'floatTarget'],
        ],
        [
          ['menuColor'],
          ['hideMenuOnPublish'],
        ]
      ]} />
  )
}

export default Inspector;