import React, { useContext, useRef, useEffect, useState } from 'react';
import Icon from 'lib/Icon';
import './ui.css';
import {
  QuestionMarkIcon, ExclamationTriangleIcon,
  FileIcon, CardStackIcon, CommitIcon, Component1Icon
} from '@radix-ui/react-icons';
import { faTimes, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'react-aria-components';
import clsx from 'clsx';

const Context = React.createContext();

export function Popup({ popup, children, className }) {
  return (
    <Context.Provider value={popup} >
      <div className={'nb-popup ' + (className || '')}>
        { children }
      </div>
    </Context.Provider>
  )
}

export function Backdrop({ children }) {
  return (<div className="popup-backdrop">{children}</div>);
}

export function Dialog({ children, onMouseDown }) {
  const
    popup = useContext(Context),
    [position, setPosition] = useState(popup.position),
    loading = popup.state.get('loading'),
    style = {
      width: position.get('width') + 'px',
      height: position.get('height') ? position.get('height') + 'px' : undefined,
      left: (position.get('dragging') ? position.get('dragX') : position.get('left')) + 'px',
      top: (position.get('dragging') ? position.get('dragY') : position.get('top')) + 'px'
    },
    ref = useRef();

  useEffect(
    _ => popup.emitter.on('change', _ => setPosition(popup.position)),
    [popup]
  );
  useEffect(_ => {
    window.addEventListener('resize', popup.handleWindowResize);
    return _ => window.removeEventListener('resize', popup.handleWindowResize);
  }, [popup]);

  useEffect(_ => {
    if (!ref.current)
      return;
    const resizeObserver = new ResizeObserver(_ => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        popup.setObservedRect(rect);        
      }
    });
    const el = ref.current;
    resizeObserver.observe(el);
    return _ => resizeObserver.unobserve(el);
  }, [popup]);

  return (
    <div
      className="popup-dialog fixed z-50 border border-rim bg-paper shadow-lg rounded"
      style={style}
      ref={ref}
      onMouseDown={e => {
        if (onMouseDown)
          onMouseDown(e);
        popup.bringToFront();
      }}>
      {children}
      { loading && <div className="absolute bg-rim2 h-[4px] w-[80%] bottom-0 nb-popup-loader-bar"></div> }
    </div>
  );
}

export function TitleBar({ children }) {
  const
    popup = useContext(Context),
    ref = useRef();
  
  return (
    <div
      className="popup-title-bar user-select-none text-center border-b border-rim flex items-center select-none"
      onMouseDown={e => {
        if (e.target === ref.current)
          popup.startDrag(e);
      }}
      ref={ref}>
      {children}
    </div>
  );
}

export function Body({ className, children }) {
  return (<div className={className || 'px-4 py-2'}>{children}</div>);
}

export function Title({ children }) {
  const popup = useContext(Context);  
  return (
    <div className="flex-grow p-1.5 popup-title inline-block font-medium text-sm" onMouseDown={e => popup.startDrag(e) }>
      { children }
    </div>
  );
}

export function CloseButton() {
  const popup = useContext(Context);
  return (
    <Button
      className="text-sm p-1.5 absolute right-1.5 top-0 text-pencil2"
      onPress={() => {
        popup.closeButtonClicked = true;
        popup.close();
      }}>
      <Icon faIcon={faTimes} />
    </Button>
  );
}

export function Warning({ title, children }) {
  return (
    <div className="flex my-3 py-4 px-4 gap-4 border-l-2 border-red-200 bg-red-50">
      <div className="text-5xl py-2">
        <ExclamationTriangleIcon className="text-red-700 w-8 h-8" />
      </div>
      <div className="">
        <div className="text-red-800 font-bold mb-1">{title}</div>
        <div className="text-red-800 text-sm">
          {children}
        </div>
      </div>
    </div>
  );
}


export function Alert() {
  const
    popup = useContext(Context),
    alert = popup.state.get('alert');

  if (!alert)
    return '';
  
  return (
    <div className="bg-red-100 border-red-500 text-red-700 border-l-4 p-4 text-sm">
      <div
        className="float-right cursor-pointer px-2"
        onClick={_ => popup.setState({ alert: null })}>
        <Icon faIcon={faTimes} />
      </div>
      <Icon faIcon={faCircleExclamation} /> &nbsp;&nbsp;{alert}
    </div>
  );
}

function Question({ children }) {
  return (
    <div className="flex my-2 gap-4 content-start p-2 ">
      <div className="border-2 border-pencil3 text-paper p-2 bg-pencil2 mt-1">
        <QuestionMarkIcon className="w-6 h-6" />
      </div>
      <div className="body">
        {children}
      </div>
    </div>
  );
}

function Item({ itemType, title, slug, className }) {
  const Ico = (
    itemType === 'Article' ? FileIcon :
      itemType === 'Folder' ? CardStackIcon :
        itemType === 'Version' ? CommitIcon :
          itemType === 'Domain' ? Component1Icon : null);

  return (
    <div className={clsx('flex gap-3 mb-2 p-1 text-left border border-rim rounded-sm p-2', className)}>
      <div className="p-1">
        <Ico className="w-6 h-6" />
      </div>
      <div className="body">
        <div className="text-sm font-medium">{title}</div>
        <div className="font-mono text-xs">{slug}</div>
      </div>
    </div>
  )
}

function Info({ title, children }) {
  return (
    <div className="bg-orange-100 border-orange-500 text-orange-700 border-l-4 p-4 my-3">
      {title && (
        <div className="text-orange-800 font-medium mb-1 pb-1 border-b border-orange-700">{title}</div>
      ) }
      <div className="text-sm ">{children}</div>      
    </div>
  );
}

function Window({ popup, title, children }) {
  return (
    <Popup popup={popup}>
      <Dialog>
        <TitleBar>
          <Title>{title}</Title>
          <CloseButton />
        </TitleBar>
        <Body>
          {children}
        </Body>
      </Dialog>
    </Popup>
  )
}

const index = {
  Popup, Backdrop, Dialog, TitleBar, Body,
  CloseButton, Title, Item,
  Info, Warning, Alert, Question,
  Window
}

export default index;