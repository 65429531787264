import Prompt from 'lib/popup/popups/Prompt/Controller';
import RichText from 'lib/popup/popups/RichText/Controller';
import DomainPreferences from 'modules/domain/popups/DomainPreferences/Controller';
import UploadImage from 'modules/app/popups/UploadImage/Controller';
import ComingSoon from 'modules/app/popups/ComingSoon/Controller';
import RenameSlug from 'modules/folder/popups/RenameSlug/Controller';

import DomainAccess from 'modules/domain/popups/DomainAccess/Controller';
import DomainUsage from 'modules/domain/popups/DomainUsage/Controller';
import DomainUpgrade from 'modules/domain/popups/DomainUpgrade/Controller';
import { SingleColumn, DoubleColumn } from './images';


export default class Popups {
  constructor(parent) {
    this.parent = parent;
  }

  get popupManager() {
    return this.parent.popupManager;
  }

  get domainId() {
    return this.domain.get('id');
  }

  get domain() {
    return this.parent.state.get('domain');
  }

  get provider() {
    return this.parent.provider;
  }

  editTitlePopup = () => {
    const title = this.parent.state.getIn(['domain', 'title']);

    this.popupManager.open(Prompt, {
      title: 'Edit Workspace Title',
      submitLabel: 'Save',
      inputs: [{
        label: 'Workspace Title',
        type: 'text',
        defaultValue: title,
        autoFocus: true
      }],
      onSubmit: async ([title]) => {
        await this.parent.updateDomain({ title });
      }
    }, this.domainId);
  }

  renameSlugPopup = () => {
    this.popupManager.open(RenameSlug, {
      domain: this.domain,
      provider: this.provider
    });
  }  

  editDescriptionPopup = () => {
    const description = this.parent.state.getIn(['domain', 'description']);
    this.popupManager.open(RichText, {
      title: 'Edit Description',
      format: 'html',
      text: description,
      onSave: async (text) => {
        await this.parent.updateDomain({ description: text });
      }
    }, this.domainId);
  }

  editThumbnailPopup = () => {
    const
      domain = this.parent.state.get('domain'),
      imageUrl = domain.getIn(['thumbnail', '640x640']);

    return this.uploadImagePopup({
      getImage: async () => (imageUrl ? this.parent.provider.getDownloadURL(imageUrl) : null ),
      basePath: `domain/${this.domainId}/thumbnail`,
      placeholderText: 'Upload Cover Picture',
      onUpload: data => { this.parent.setState({ uploadedThumbnailImageData: data, oldThumbnailImageUrl: imageUrl }); },
      onRemoveClick: _ => this.removeImagePopup({
        title: 'Remove the Cover Image?',
        question: 'Are you sure you want to remove the cover image?',
        key: 'thumbnail'
      })
    });
  }

  editLogoPopup = () => {
    const
      domain = this.parent.state.get('domain'),
      imageUrl = domain.getIn(['logo', '320x320']);

    return this.uploadImagePopup({
      getImage: async () => (imageUrl ? this.parent.provider.getDownloadURL(imageUrl) : null ),
      basePath: `domain/${this.domainId}/logo`,
      title: 'Upload Logo',
      placeholderText: 'Upload Logo',
      imageWidth: 150,
      imageHeight: 150,
      onUpload: data => { this.parent.setState({ uploadedLogoImageData: data, oldLogoImageUrl: imageUrl }); },
      onRemoveClick: _ => this.removeImagePopup({
        title: 'Remove the Logo?',
        question: 'Are you sure you want to remove the logo?',
        key: 'logo'
      })
    });
  }

  uploadImagePopup = ({
    title, getImage, basePath, imageWidth=300, imageHeight=300,
    placeholderText, onUpload, onRemoveClick
  }) => {
    const
      provider = this.parent.provider,
      uploadImageFn = (
        ({ filename, file }, progress, error, done) =>
          provider.uploadImage({ basePath, filename, file }, progress, error, done).catch(error)
      );

    this.popupManager.open(UploadImage, {
      title,
      placeholderUrl: null,
      uploadImageFn: uploadImageFn,
      getImage,
      onUpload,
      placeholderText,
      imageWidth,
      imageHeight,
      onRemoveClick,
    }, this.domainId);
  }

  removeImagePopup = ({ title, question, key }) => {
    const provider = this.parent.provider;

    this.popupManager.open(Prompt, {
      title, question,
      submitLabel: 'Remove',
      inputs: [],
      width: 380,
      onSubmit: async () => {
        await provider.removeThumbnail({ domainId: this.domainId, key });
        this.parent.setState({ uploadedThumbnailImageData: null });
      }
    });
  }

  editAccessPopup = () => {
    this.popupManager.open(DomainAccess, { domain: this.domain, provider: this.provider }, this.domainId);
  }

  editPreferencesPopup = () => {
    this.popupManager.open(DomainPreferences, { domain: this.domain, provider: this.provider }, this.domainId);
  }

  usagePopup = () => {
    this.popupManager.open(DomainUsage, {
      domain: this.domain,
      provider: this.provider,
      upgradePopup: this.upgradePopup,
    }, this.domainId);
  }

  upgradePopup = () => {
    this.popupManager.open(DomainUpgrade, {
      domain: this.domain,
      provider: this.provider,
    }, this.sessionId);
  }

  billingPopup = () => {
    this.popupManager.open(ComingSoon, {});
  }

  getPaidPopup = () => {
    this.popupManager.open(ComingSoon, {});
  }

  editCoverPageLayoutPopup = () => {
    const { themeData } = this.parent;

    this.popupManager.open(Prompt, {
      title: 'Change Homepage Layout',
      submitLabel: 'Save',
      inputs: [{
        label: 'Homepage Layout',
        type: 'radio',
        defaultValue: themeData.getValue('coverPageLayout'),
        autoFocus: true,
        options: [[
          'single',
          'Single Column',
          <Preview Image={SingleColumn} description="" />
        ], [
          'double',
          'Double Columns',
          <Preview Image={DoubleColumn} description="" />
        ]]
      }],
      onSubmit: async ([ coverPageLayout, coverPageHasImage ]) => {
        themeData.setData({ coverPageLayout });
        await this.parent.updateDomain({
          theme: themeData.toJS(),
        });
      }
    }, this.domainId);
  }

  editAllowComments = () => {
    const
      workspace = this.domain.getIn(['allowComments', 'workspace']) || false,
      articles = this.domain.getIn(['allowComments', 'articles']) || false;

    this.popupManager.open(Prompt, {
      title: 'Allow Comments',
      question: 'Allow comments on your published workspace and articles?',
      submitLabel: 'Save',
      inputs: [{
        label: 'on Workspace:',
        type: 'checkbox',
        isYesNo: true,
        defaultValue: workspace,
        autoFocus: true,
      }, {
        label: 'on Article:',
        type: 'checkbox',
        isYesNo: true,
        defaultValue: articles,
        autoFocus: true,
      }],
      onSubmit: async ([ workspace, articles ]) => {
        await this.parent.updateDomain({ allowComments: { workspace, articles } });
      }
    }, this.domainId);

  }
}

function Preview({ Image, description }) {
  return (
    <div className="flex-grow">
      <Image className="w-28 h-24 block mx-auto bg-wax2 px-2" />
      {/*<div className="text-sm p-2 text-center">{description}</div>*/}
    </div>
  );
}
