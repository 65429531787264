import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { firebaseConfig, firebaseEmuPorts as emuPorts } from '../../config';


export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage();
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);


if (process.env.REACT_APP_ENV === 'emulator' || process.env.REACT_APP_ENV === 'hybrid') {
  console.log('Using Emulator');
  const hostname = window.location.hostname;
  connectAuthEmulator(auth, `http://${hostname}:${emuPorts.auth}`);
  connectFirestoreEmulator(db, hostname, emuPorts.firestore);
  connectStorageEmulator(storage, hostname, emuPorts.storage);
  connectFunctionsEmulator(functions, hostname, emuPorts.functions);
}