import FileBar from './FileBar';
import './style.css';

export default function IDELike({ isGrid, isFullHeight, isDark, isActive, controller, fileControllers, children }) {
  return (
    <div className="nb-code is-full-height ide-like flex flex-col h-full border-rim border">
      <FileBar {...{ isGrid, isFullHeight, isDark, isActive, controller, fileControllers }} />
      <div className="flex-grow overflow-auto flex-grow">
        {children}
      </div>
    </div>
  )
}