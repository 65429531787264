import {
  abcdef, abyss, androidstudio, atomone, aura, bbedit, bespin, darcula,
  dracula, duotoneDark, duotoneLight, eclipse, githubDark, githubLight,
  gruvboxDark, gruvboxLight, kimbie, material, materialDark, materialLight,
  monokai, monokaiDimmed, noctisLilac, nord, okaidia, quietlight, red,
  solarizedDark, solarizedLight, sublime, tokyoNight, tokyoNightDay,
  tokyoNightStorm, tomorrowNightBlue, vscodeDark, xcodeDark, xcodeLight
} from '@uiw/codemirror-themes-all';

import { basicLight, basicDark } from '@uiw/codemirror-theme-basic';

// import * as all from '@uiw/codemirror-themes-all';

const allThemes = {
  basicLight, basicDark,

  abcdef, abyss, androidstudio, atomone, aura, bbedit, bespin, darcula,
  dracula, duotoneDark, duotoneLight, eclipse, githubDark, githubLight,
  gruvboxDark, gruvboxLight, kimbie, material, materialDark, materialLight,
  monokai, monokaiDimmed, noctisLilac, nord, okaidia, quietlight, red,
  solarizedDark, solarizedLight, sublime, tokyoNight, tokyoNightDay,
  tokyoNightStorm, tomorrowNightBlue, vscodeDark, xcodeDark, xcodeLight
};

export default allThemes;