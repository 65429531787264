import { CodeIcon, ChevronRightIcon, FileIcon } from '@radix-ui/react-icons';
import { Button } from 'react-aria-components';


export default function CollapsedView({ controller, fileControllers }) {
  return (
    <div className="flex border rounded-sm relative bg-paper border-rim">
      <div className="p-4 flex items-center border-r border-rim">
        <CodeIcon />
      </div>
      <div className="flex-grow p-3.5">
        {fileControllers.map((child, i) => (
          <div key={i} className="font-mono mr-2 text-sm px-2 my-1 inline-block">
            <FileIcon className="inline" /> {child.getSettingsValue('filename')}
          </div>
        ))}
      </div>

      <Button
        className="p-4 border-l border-rim flex items-center outline-none"
        onPress={() => controller.expandDetail(true)}>
        <ChevronRightIcon />
      </Button>
    </div>
  );
}