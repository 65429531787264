import { fromJS } from 'immutable';
import BaseController from 'lib/BaseController';
import { toHuman } from 'lib/firebaseError';
import FolderController from 'modules/folder/controllers/Folder';
import ArticleController from 'modules/article/controllers/Article';
import DomainController from 'modules/domain/controllers/Domain';
import Panels from './Panels';
import openPreferencePopup from './preferences/openPopup';


class Editor extends BaseController {
  constructor({ app }) {
    super();
    this.app = app;
    this.panels = new Panels();
    this.popupManager = app.popupManager;
    this.domainController = new DomainController({ app });
    this.folderController = new FolderController({ app });
    this.articleControllers = {};
    this.state = this.state.merge(fromJS({
      currentId: null,
      articleIds: [],
      error: null,
      detailId: null
    }));
  }

  loadPath = (path) => {
    console.log('[', 'loading editor path', path, ']');
    this.setState({ error: null });
    this.tryLoadPath(path).catch(e => {
      console.error(e);
      this.setState({ error: toHuman(e) });
    });
  }

  tryLoadPath = async (path) => {
    console.log('[', 'loading path', path, ']');
    if (this.path === path)
      return;
    this.path = path;

    const
      itemPath = path.replaceAll('/', '\\'),
      domainSlug = path.split('/')[0],
      provider = (domainSlug === 'local' ? this.app.localProvider : this.app.remoteProvider),
      pathData = await provider.fetchPath(itemPath);

    if (!pathData || pathData.type !== 'article')
      throw new Error('Invalid path.');
    const { domainId, articleId } = pathData;

    this.domainController.load({ provider, domainId, slug: domainSlug });    
    this.loadArticle(domainId, articleId, provider, path);
  }

  loadArticle = (domainId, articleId, provider, path) => {
    if (!this.articleControllers[articleId]) {
      this.articleControllers[articleId] = new ArticleController({
        app: this.app,
        domain: this.domainController,
        editor: this
      });
      this.articleControllers[articleId].load({ provider, domainId, articleId, path });
      this.setState({ articleIds: this.state.get('articleIds').push(articleId) })
    }
    this.setState({ currentId: articleId });
    this.articleControllers[articleId].bringToFront();
  }

  closeArticle = (id, navigate, replace=true) => {
    let
      articleIds = this.state.get('articleIds'),
      currentId = this.state.get('currentId'),
      index = articleIds.indexOf(id),
      currentIndex = articleIds.indexOf(currentId);

    if (index <= currentIndex)
      currentIndex -= 1;

    articleIds = articleIds.delete(index);
    currentIndex = Math.min(Math.max(currentIndex, 0), articleIds.size - 1);
    currentId = articleIds.get(currentIndex);

    this.setState({ articleIds, currentId });
    if (this.articleControllers[currentId]) {
      this.articleControllers[currentId].bringToFront();
      const path = this.articleControllers[currentId].state.getIn(['article', 'path'])
      navigate('/editor/' + path, { replace });
    }
    
    this.articleControllers[id].unload();
    delete this.articleControllers[id];
  }

  get currentArticleId()  { return this.state.get('currentId') }
  get currentArticleController() { return this.articleControllers[this.currentArticleId]; }
  get articleController() { return this.currentArticleController; }

  unload = () => {
    console.log('[ unloading', this.path, ']');
    for (var id in this.articleControllers) {
      this.articleControllers[id].unload();
    }
    this.articleControllers = {};
    this.setState({ articleIds: fromJS([]) });
    this.folderController.unload();
    this.path = null;
  }

  moveArticleId(sourceIndex, destIndex) {
    var
      articleIds = this.state.get('articleIds'),
      item = articleIds.get(sourceIndex);
    articleIds = articleIds.splice(sourceIndex, 1).splice(destIndex, 0, item);
    
    this.setState({ articleIds });
  }

  openPreferencePopup() {
    openPreferencePopup(this);
  }

  expandDetail(id) {
    this.panels.saveInState(['expand', 'right'], id && `detail-${id}`);
  }
}

export default Editor;