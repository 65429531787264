import clsx from 'clsx';
import { HeightResizer } from 'modules/elements/lib/components/resizers';
import TopFileBar from './TopFileBar';
import LeftFileBar from './LeftFileBar';
import FloatingFileBar from './FloatingFileBar';

import TopLineSpacer from './components/TopLineSpacer';
import ModeSelection from './components/ModeSelection';
import MenuButton from './components/MenuButton';


export function Switch({ isGrid, isFullHeight, isDark, isActive, controller, fileControllers, layout, children }) {
  const
    { getSettingsValue, setSettings } = controller,
    Layout = ({
      'top': TopPaneled,
      'left': LeftPaneled,
      'float': FloatingPaneled,
    })[layout] || TopPaneled;

  return (
    <div
      id={'code-' + controller.elementId}
      className={clsx(
        'nb-code font-code relative rounded-sm',
        isDark ? 'color-scheme-dark' : 'color-scheme-light',
        isGrid && 'is-grid',
        isFullHeight && 'h-full overflow-hidden is-full-height',
      )}
      key={getSettingsValue('autoHeight')}>
      <Layout {...{ isGrid, isFullHeight, isDark, isActive, controller, fileControllers, layout }}>
        {children}
      </Layout>

      { !controller.isReadOnly && !isGrid && layout !== 'detail' && !getSettingsValue('autoHeight') && (
        <HeightResizer onChange={(dy) => {
          let
            height = getSettingsValue('height') + dy,
            range = controller.settingsFields.height.range;
          height = Math.max(Math.min(height, range[1]), range[0])
          setSettings('height', height);
        }} />
      )}
    </div>
  )
}

function TopPaneled({ isGrid, isFullHeight, isDark, isActive, controller, fileControllers, children }) {
  return (
    <div className={clsx(isFullHeight && 'h-full flex flex-col', 'border border-rim')}>
      <TopFileBar {...{ isGrid, isFullHeight, isDark, isActive, controller, fileControllers }} />
      <div className={clsx(isFullHeight && 'flex-grow overflow-auto')}>
        { children }
      </div>
    </div>
  )  
}

function LeftPaneled({ isGrid, isFullHeight, isDark, isActive, controller, fileControllers, children }) {
  const fileController = fileControllers.get(controller.currentIndex);

  return (
    <div className={clsx('flex', isFullHeight && 'h-full', 'border border-rim')}>
      <LeftFileBar {...{isGrid, isFullHeight, isDark, isActive, controller, fileControllers}} />
      <div className="flex-grow overflow-auto">{ children }</div>

      <TopLineSpacer elementId={controller.elementId} content="- - - - - -" />
      <div className="flex absolute top-1 right-3 items-center gap-0">
        <ModeSelection controller={controller} fileController={fileController} />
        <MenuButton controller={controller} isGrid={isGrid} isVertical={true} />
      </div>
    </div>
  )  
}


function FloatingPaneled({ isGrid, isFullHeight, isDark, isActive, controller, fileControllers, children }) {
  let n = 0;
  fileControllers.forEach(fc => {
    n = n + (fc.getSettingsValue('filename').length)
  });
  n = n === 0 ? 4 : (n + fileControllers.size * 3 + 4);

  return (
    <div className={clsx('relative flex flex-col', isFullHeight && 'h-full', 'border border-rim')}>
      <div className="flex-grow overflow-auto">
        { children }        
      </div>
      <FloatingFileBar {...{isGrid, isFullHeight, isDark, isActive, controller, fileControllers}} />
      <TopLineSpacer elementId={controller.elementId} content={'-'.repeat(n)} />
    </div>
  )  
}
