import { useState } from 'react';
import { Link, Button, TooltipTrigger, Tooltip } from 'react-aria-components';
// import Leaf from './components/Leaf';
import Menu from './components/Menu';
import clsx from 'clsx';
import Logo from './components/Logo';
import { useBookmark } from './components/bookmarks';
import { ChevronLeftIcon, ChevronRightIcon, RowsIcon } from '@radix-ui/react-icons';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';


export default function Wrapper({ viewer, children }) {
  return (
    <div className="h-full w-full overflow-hidden wrapper">
      {children}
      <Topbar viewer={viewer} />
      <LeftPanel viewer={viewer} />
      <RightPanel viewer={viewer} />
    </div>
  )
}

function Topbar({ viewer }) {
  const
    showMenu = viewer.state.get('showMenu'),
    autoHideMenu = viewer.state.get('autoHideMenu'),
    { domain } = viewer.workspace,
    [isOpen, setIsOpen] = useState(false),
    isArticle = viewer.state.get('type') === 'article';

  return (
    <div
      className={clsx(
        'absolute left-0 top-0 flex w-full py-3 px-4',
        (!showMenu || autoHideMenu) && !isOpen && 'opacity-0 hover:opacity-100',
      )}>
      <div className={clsx("flex items-center p-2 gap-4 rounded")}>
        <Link
          className="p-1 outline-none"
          href={viewer.state.get('type') === 'article' ? `/${domain.slug}` : '/' }>
          <Logo viewer={viewer} />
        </Link>

        { isArticle && (
          <Button
            className="p-2 outline-none"
            onPress={() => viewer.setPanelLeft('folder')}>
            <RowsIcon />
          </Button>
        ) }
      </div>

      <div className="flex-grow">
      </div>
      
      <div>
        <div className="flex py-2 px-2 gap-2 rounded">
          { isArticle &&  (<BookmarkButton viewer={viewer} />)}
          <ArrowButtons viewer={viewer} />
          <Menu viewer={viewer} onOpen={setIsOpen} />
        </div>
      </div>
      {/*) }*/}
    </div>
  )
}

function ArrowButtons({ viewer }) {
  const [prev, next] = viewer.getNextPrevPaths();

  return (
    <>
      <BarButton Icon={ChevronLeftIcon} href={`/${prev}`} isDisabled={!prev} tooltip="Previous" />
      <BarButton Icon={ChevronRightIcon} href={`/${next}`} isDisabled={!next} tooltip="Next" />
    </>
  );
}

// function LeafButton({ viewer }) {
//   return (
//     <Leaf viewer={viewer}>
//       <BarButton Icon={DashboardIcon} tooltip="Index" />
//     </Leaf>
//   );
// }

function BookmarkButton({ viewer }) {
  const { toggleBookmark, Icon } = useBookmark(viewer);
  return (<BarButton Icon={Icon} onPress={toggleBookmark} tooltip="Bookmark" />)
}


function BarButton({ Icon, className, onPress, href, isDisabled, tooltip }) {
  const Input = href ? Link : Button

  return (
    <TooltipTrigger>
      <Input
        className={({ isDisabled }) => clsx(
          'p-2 outline-none',
          isDisabled ? 'opacity-30 cursor-default' : 'hover:bg-wax2',
          className
        )}
        onPress={onPress}
        href={href}
        isDisabled={isDisabled}>
        <Icon className="w-4 h-4" />
      </Input>

      { tooltip && (
        <Tooltip
          placement="bottom"
          className="text-sm bg-gray-800 text-gray-100 p-1 px-3">{tooltip}</Tooltip>
      )}
    </TooltipTrigger>
  )
}