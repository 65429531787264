import { HTMLElement } from 'lib/prosemirror/helpers';
import { Button } from 'react-aria-components';


export default function Caption({ caption, onPress, maxWidth, width, isDisabled, className }) {
  if (!caption)
    return '';

  const htmlEl = <HTMLElement html={caption} />

  return (
    <div
      className={className || 'text-sm mt-2'}
      style={{ maxWidth: maxWidth, width: width || '100%' }}>
      { (onPress && !isDisabled) ?
        (<Button
          className="outline-none w-full"
          onPress={onPress}>{htmlEl}</Button>) :
        (htmlEl)
      }
    </div>
  )
}