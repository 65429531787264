import { DotIcon } from '@radix-ui/react-icons';
import { Link } from 'react-aria-components';
import clsx from 'clsx';


export default function FolderContent({ viewer }) {
  const
    sections = viewer.workspace.sections.filter(s => s.items.length > 0),
    l = sections.length;

  return (
    <div
      className={clsx(
        l === 2 && 'md:columns-2',
        l > 2 && 'md:columns-3',
      )}>
      { sections.map((section, i) => section.items.length > 0 && (
        <div className="section mb-4 avoid-column-break" key={i}>
          { section.title && (
            <div className="text-pencil3 px-2">
              {section.title}
            </div>
          ) }

          <div className="items">
            {section.items.map(item => (
              <FolderItem key={item.id} item={item} viewer={viewer} className="text-md" />
            ))}
          </div>
        </div>
      )) }
    </div>
  )
}


export function FolderItem({ item, viewer, className }) {
  const
    // { publishedVersionTitle, path, id, type } = item,
    { publishedVersionTitle, path, type } = item,
    isFolder = type === 'folder'; // ,
    // isOpen = !!viewer.articles[id],
    //  isActive = id === viewer.state.get('folderId') || id === viewer.state.get('articleId');

  return (
    <div className={clsx('flex items-top', className || 'text-sm')}>
      {/*<div className="pt-2">
        { isOpen ? (
          <Button
            className={({ isDisabled }) => clsx(
              isDisabled && 'disabled',
              'group opacity-60',
              'close-button outline-none'
            )}
            isDisabled={isActive}
            onPress={(e) => viewer.closeArticle(id)}>
            <div className={clsx(!isActive && 'group-hover:hidden')}><DotFilledIcon /></div>
            <div className={clsx('hidden ', !isActive && 'group-hover:block')}><Cross2Icon /></div>
          </Button>
        ) : (
        ) }
      */}
      <div className="pt-2">
        <DotIcon />
      </div>

      <Link
        className={clsx(
          'flex-grow py-1 px-2 outline-none hover:bg-wax2 !font-normal',
          isFolder ? '!text-marker2' : '!text-pencil'
        )}
        href={'/' + path.replace('\\', '/')}>
        { publishedVersionTitle }
      </Link>
    </div>
  );
}