const settingsFields = {
  /**** Display Settings ****/
  title: { caption: 'Title', input: 'text', init: '' },

  layout: {
    caption: 'Frame Layout', input: 'options', init: 'dynamic',
    options: [
      { caption: 'Full Width, Dynamic', key: 'dynamic' },
      { caption: 'Fixed Box', key: 'fixed' },
    ]
  },

  fixedWidth: { caption: 'Fixed Width', input: 'number', init: 640, unit: 'px', range: [300, 2000] },

  fixedHeight: { caption: 'Fixed Height', input: 'number', init: 320, unit: 'px', range: [100, 2000] },

  scaling: {
    caption: 'Scaling', input: 'options', init: 'down',
    options: [
      { key: 'down', caption: 'Scale Down Only' },
      { key: 'both', caption: 'Stretch to Full Width' },
      { key: 'none', caption: 'No Scaling' },
    ]
  },

  alignment: {
    caption: 'Alignment', input: 'options', init: 'center',
    options: [
      { key: 'left', caption: 'Left' },
      { key: 'center', caption: 'Center' },
      { key: 'right', caption: 'Right' },
    ]
  },

  /* Full */
  autoHeight: {
    caption: 'Auto Height', input: 'options', init: false,
    options: [
      { caption: 'Yes', key: true },
      { caption: 'No', key: false }
    ]
  },

  /* Both */
  height: {
    captionFn: getValue => (getValue('autoHeight') ? 'Start Height': 'Fixed Height'),
    input: 'number', init: 320, unit: 'px', range: [100, 2000]
  },

  hasBorder: { caption: 'Has Border', input: 'checkbox', init: true  },


  /**** On Published ****/
  autorun: { caption: 'Autorun on Published', input: 'checkbox', init: true },
  showTarget: { caption: 'Show Target', input: 'checkbox', init: true },
  floatTarget: {
    caption: 'Float Target', input: 'checkbox', init: false,
    isHiddenFn: getValue => !getValue('showTarget')
  },
  hideMenuOnPublish: { caption: 'Hide Menu on Published', input: 'checkbox', init: false },

  menuColor: {
    caption: 'Menu Color',
    input: 'options',
    init: null,
    options: [
      { caption: 'Default', key: null },
      { caption: 'Dark', key: 'dark' },
      { caption: 'Light', key: 'light' }
    ],
    isHiddenFn: getValue => !(getValue('showTarget') && getValue('floatTarget')),
  },

  /**** Runtime settings ****/
  runtime: {
    caption: 'Runtime', input: 'options', init: 'standard',
    options: [
      { key: 'standard', caption: 'Standard' },
      { key: 'pure', caption: 'Pure' }
    ]
  },

  target: { caption: 'Target Filename', input: 'text', init: '' },

  renderOption: {
    caption: 'Component Render Option',
    input: 'options',
    init: 'default',
    options: [
      { key: 'none', caption: 'None' },
      { key: 'default', caption: 'Default exported component' },
      { key: 'export', caption: 'Exported component' }
    ],
    isHiddenFn: getValue => getValue('runtime') !== 'standard',
  },

  classToRender: {
    caption: 'Render Component',
    input: 'text',
    init: '',
    isHiddenFn: getValue => getValue('runtime') !== 'standard' || getValue('renderOption') !== 'export',
  },
  
  importMap: {
    caption: 'Import Map', input: 'json', init: {}
  },
}

export const defaultImportMap = {
  'react': '/libraries/react.development.18.2.0.mjs',
  'react-dom': '/libraries/react-dom.development.18.mjs',
  'immutable': '/libraries/immutable.min.js'
}

export const initialSettings = {};
for (let k in settingsFields) { initialSettings[k] = settingsFields[k].init; }

export default settingsFields;