import ResizeBar from './components/ResizeBar';
import BarFiles from './components/BarFiles';
import clsx from 'clsx';


export default function LeftFileBar({ controller, isGrid, isActive, isDark, fileControllers }) {
  const
    width = controller.getSettingsValue('verticalFileBarWidth') || 200,
    setWidth = (w) => controller.setSettings('verticalFileBarWidth', w);

  return (
    <div
      className={clsx('border-r border-rim flex-shrink-0 py-1 overflow-auto bg-wax', isDark && 'border-pencil')}
      style={{ width: width }}>
      <BarFiles
        controller={controller}
        fileControllers={fileControllers}
        isGrid={isGrid}
        isActive={isActive}
        isDark={isDark}
        direction="vertical"
        buttonClassName={({isActive}) => clsx('py-2 px-4', '')} />

      <ResizeBar
        width={width}
        onChange={dx => { setWidth(Math.min(Math.max(width + dx, 100), 300)) }} />
    </div>
  );
}