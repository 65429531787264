import { useState, useEffect, useRef } from 'react';
import { Pencil1Icon } from '@radix-ui/react-icons';
import DOMPurify from 'dompurify';
import Excalidraw from './Excalidraw';
import { ReactComponent as Logo } from './logo.svg';
import Button from 'modules/elements/lib/components/Button';
import Caption from 'modules/elements/lib/components/Caption';
import clsx from 'clsx';

import './style.css';


function Editor({ controller, isGrid }) {
  const
    { state, setState, getSettingsValue, isReadOnly } = controller,
    [ hash, setHash ] = useState(window.location.hash),
    content = state.get('content'),
    alignment = getSettingsValue('alignment'),
    hasBorder = getSettingsValue('hasBorder'),
    backgroundColor =  getSettingsValue('backgroundColor') || 'rgb(var(--color-paper))',
    svg = content && content.svg,
    
    ref = useRef(),
    [rect, setRect] = useState(null);

  useEffect(() => {
    const handleHashChange = (e) => setHash(window.location.hash);
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);


  useEffect(_ => {
    if (!ref.current)
      return;
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        setRect(ref.current.getBoundingClientRect())
      }
    });
    const el = ref.current;
    resizeObserver.observe(el);
    return () => resizeObserver.unobserve(el);
  }, []);

  return (
    <>
      <div
        className={clsx(
          'nb-excali-editor relative group/excali',
          // border && ' has-border',
          isGrid && ' is-grid'
        )}
        style={{ textAlign: alignment }}
        onClick={controller.activate}>
        
        <div
          className={clsx(
            !svg && 'bg-striped',
            hasBorder && 'border border-rim p-2',
            'image-container inline-block relative min-w-[8rem] min-h-[8rem]'
          )}
          style={{ backgroundColor: backgroundColor || 'none' }}
          ref={ref}>
          { svg && <Image controller={controller} svg={svg} /> }
          { !svg && !isReadOnly && <ImagePlaceholder controller={controller} /> }
          {!isReadOnly && <Menu controller={controller} /> }
        </div>
        {getSettingsValue('caption') && (
          <>
            <br/>
            <Caption
              caption={getSettingsValue('caption')}
              className="text-sm mt-2 inline-block"
              onPress={() => controller.editCaption()}
              width={rect && rect.width}
              isDisabled={isReadOnly}
              alignment={alignment} />
          </>
        )}
      </div>
      { hash.substring(1) === controller.elementId  && (
        <Excalidraw
          setState={setState}
          controller={controller} /> 
      ) }
    </>
  );
}


function Image({ svg }) {
  const __html = DOMPurify.sanitize(svg, {
    USE_PROFILES: { svg: true, svgFilters: true }
  });
  return (<div className="image inline-block relative" dangerouslySetInnerHTML={{ __html }} />);
}


function ImagePlaceholder({ controller }) {
  return (
    <div className="placeholder w-full h-full flex absolute">
      <div className="w-[8rem] text-center block p-3 m-auto">
        <div className="logo w-6 inline-block">
          <Logo />
        </div>
        <div>
          <Button
            className=""
            onPress={() => controller.startDrawing()}>
            Start Drawing
          </Button>          
        </div>
      </div>
    </div>
  )
}


function Menu({ controller }) {
  return (
    <div className="absolute top-2 right-2 hidden group-hover/excali:block">
      <Button
        onPress={() => controller.startDrawing()}
        className="p-1 rounded-sm bg-paper border-rim border"
        tooltip="Edit">
        <Pencil1Icon className="block" />
      </Button>
    </div>
  )
}

export default Editor;