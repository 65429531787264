import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Button } from 'react-aria-components';
import clsx from 'clsx';


export default function Files({
  controller, isGrid, isActive, isDark, direction, fileControllers,
  buttonClassName, buttonsClassName, pre
}) {
  const
    { isReadOnly } = controller,
    hideFilenames = fileControllers.size === 1 && fileControllers.get(0).getSettingsValue('filename') === '',
    onDragEnd = ({ source, destination }) => {
      if (destination && source.index !== destination.index) {
        controller.moveOrderIndex(source.index, destination.index);
      }
    };

  return !hideFilenames && (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="file-tabs"
        direction={direction === 'vertical' ? 'vertical' : 'horizontal'}>
        { provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={clsx(
              'file-tabs flex',
              buttonsClassName,
              direction === 'vertical' && 'flex-col'
            )}>
            <div className="flex-grow"></div>
            { fileControllers.map((fileController, index) => {
              return (
                <DraggableTab
                  key={fileController.elementId}
                  id={fileController.elementId}
                  fileController={fileController}
                  index={index}
                  isElementActive={isActive}
                  isActive={controller.currentIndex === index}
                  multi={fileControllers.size > 1}
                  isReadOnly={isReadOnly}
                  pre={pre}
                  buttonClassName={buttonClassName}
                  isDark={isDark}
                  setAsCurrent={() => { controller.setCurrentFileIndex(index) }}
                  editFilename={() => { fileController.editFilenamePopup({ allowEmpty: true }); }} />);
            })}
            { provided.placeholder }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function DraggableTab({
  id, index, setAsCurrent, editFilename, isActive, isElementActive,
  multi, isReadOnly, direction, isDark, fileController, buttonClassName, pre
}) {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isReadOnly}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} style={provided.draggableProps.style}>
          <div ref={provided.innerRef} {...provided.dragHandleProps}>
            <Button
              className={clsx(
                'break-words text-nowrap outline-none',
                // (multi || !isReadOnly) ? 'cursor-pointer' : 'cursor-default',
                multi && (isActive ? `font-bold text-pen` : 'text-pencil2'),
                (typeof buttonClassName === 'function' ? buttonClassName({ isActive }) : buttonClassName),
              )}
              isDisabled={!multi || isActive}
              onPress={setAsCurrent}>
              {pre}
              <Filename fileController={fileController} />
            </Button>
          </div>
        </div>
       )}
    </Draggable>
  )
}

function Filename({ fileController }) {
  const
    filename = fileController.getSettingsValue('filename'),
    [, setCurrentState] = useState(fileController.state);

  useEffect(() => fileController.onStateChange(() => setCurrentState(fileController.state)), [fileController]);
  return (<>{filename || 'untitled'}</>);
}