import { useRef } from 'react';
import { Label } from './components';
import { ResetIcon } from '@radix-ui/react-icons';
import { Button } from 'react-aria-components';
import clsx from 'clsx';


export default function ColorInput(props) {
  const
    inputRef = useRef(),
    { value, isDisabled, valueOnNull, onChange, nullable, label } = props,
    isNull = value === null;

  return (
    <div className={clsx('nb-sb-color-input group/color-input', isDisabled && ' pointer-events-none')}>
      <Label>{label}</Label>
      <div className="pl-1">
        <Palette
          onClick={e => inputRef.current.click()}
          colorCode={isNull ? valueOnNull : value} />        

        { nullable && !isNull && (
          <Button className="ml-2 hidden group-hover/color-input:inline-block" onClick={_ => onChange(null)}>
            <ResetIcon className="inline" />
          </Button>
        )}
        
        <input
          value={value || valueOnNull || '#000000'}
          className="w-0 h-0 outline-none opacity-0 inline"
          onChange={e => onChange(e.currentTarget.value)}
          ref={inputRef}
          type="color" />
        </div>
    </div>
  );
}

export function Palette({ colorCode, onMouseDown, onClick }) {
  return (
    <div
      className={clsx(
        'nb-sb-color-palette w-4 h-4 cursor-pointer inline-block align-middle border border-rim2 rounded'
      )}
      style={{ background: colorCode || `repeating-linear-gradient( 45deg, rgba(var(--color-paper)), rgba(var(--color-paper)) 3px, rgba(var(--color-wax2)) 3px, rgba(var(--color-wax2)) 6px)` }}
      onMouseDown={onMouseDown}
      onClick={onClick} />
  );
}