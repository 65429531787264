import { memo } from 'react';
import { useControllerState } from 'lib/BaseController';
import Comments from '../Comments';

export default function RightPanel({ viewer }) {
  const
    panelRight = viewer.state.get('panelRight'),
    Panel = (
      panelRight === 'detail' ? DetailPanel :
        panelRight === 'comments' ? CommentsPanel :
        null
    );

  return Panel && (<Panel viewer={viewer} />)
}

function DetailPanel({ viewer }) {
  const
    detailId = viewer.state.get('detailId'),
    a = viewer.currentArticle,
    element = a && a.elementControllers[detailId];

  return element && (
    <div className="fixed top-4 right-6 bottom-6 w-[39rem]">
      <MemoizedElement viewer={viewer} element={element} />
    </div>
  )
}

export function Element({ editor, element }) {
  useControllerState(element);
  return (
    <element.lib.Editor
      controller={element.controller}
      isActive={false}
      isGrid={false}
      isDetail={true} />)
}
const MemoizedElement = memo(Element);


function CommentsPanel({ viewer }) {
  return (
    <div className="fixed top-14 right-6 bottom-6 w-[24rem] border border-rim bg-paper">
      <Comments
        viewer={viewer}
        close={() => viewer.setState({ panelRight: null })} />
    </div>
  )
}



// function CommentsModal({ viewer }) {
//   return (
//     <ModalOverlay
//       className="fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-sm"
//       isKeyboardDismissDisabled={true}
//       isOpen={viewer.state.get('showComments')}
//       onOpenChange={b => viewer.setState({ showComments: b }) }>
//       <Modal
//         className="w-full h-full max-w-[40rem] max-h-[90%] border-rim border bg-paper relative">
//         <Comments viewer={viewer} close={e => viewer.setState({ showComments: false })} />
//       </Modal>
//     </ModalOverlay>
//   );
// }