import { useState, useEffect, useRef } from 'react';
import { useControllerState } from 'lib/BaseController';
import GridElement from './GridElement';
import Outlines from './Outlines';
import clsx from 'clsx';
import './style.css'


function GridLayout({ renderedElements, layout, hidden }) {
  if (!layout.grid.grid)
    return '';

  return (
    <GridPage layout={layout} hidden={hidden}>
      {layout.order.map((id, index) => (
        <GridElement
          key={id}
          id={id}
          index={index}
          layout={layout}
          renderedElement={renderedElements[id]} />
      ))}
    </GridPage>
  )
}

function GridPage({ layout, children, hidden }) {
  const
    pageRef = useRef(),
    [dragging, setDragging] = useState(false),
    { grid, selection } = layout,
    { camera, selector, adder, pageRect } = grid,
    gridState = useControllerState(grid),
    cameraState = useControllerState(camera),
    cursor = gridState.get('cursor'),
    styleCursor = (
      cameraState.get('dragging') ? 'grabbing' :
      cursor === 'move' ? 'grab' :
      cursor === 'default' ? 'auto' :
      cursor === 'add' ? 'crosshair' :
      ''
    );

  useEffect(() => selector.on('change', () => setDragging(selector.state.get('dragging'))), [selector]);
  useEffect(() => adder.on('change', () => setDragging(adder.state.get('dragging'))), [adder]);

  return (
    <div
      className={clsx(
        hidden && 'hidden',
        'w-full h-full overflow-auto relative',
      )}
      ref={camera.setStageEl}
      style={{ cursor: styleCursor }}>
      <div className="relative text-center inline-block min-w-full min-h-full overflow-hidden -mb-2">
        <div
          className="absolute w-full h-full top-0 left-0"
          onMouseDown={e => {
            if (!pageRef.current)
              return;

            const
              rect = pageRef.current.getBoundingClientRect(),
              x = e.clientX - rect.left,
              y = e.clientY - rect.top;

            if (cursor === 'move' || e.button === 1) {
              camera.startDrag(e, x, y);
              e.preventDefault();
              selection.focusPage();
              selection.blurPage();
            } else if (cursor === 'default') {
              selector.startDrag(e, x, y);
            } else if (cursor === 'add') {
              adder.startDrag(e, x, y);
            }
          }} />

        <div className={clsx(
          'inline-block m-auto text-left',
          'user-select-none pt-12 px-12 pb-20 transition-[padding]',
          'group-[.has-sidebar-sm]:pr-[21rem]', // 18 + 3
          'group-[.has-sidebar-lg]:pr-[43rem]' // 40 + 3

          )}>
          <div
            className={clsx('nb-grid-elements relative pointer-events-none', dragging && 'dragging')}
            ref={(el) => { pageRef.current = el;  }}
            style={{
              width: pageRect.width,
              height: pageRect.height,
            }}>
            <Outlines grid={grid} />
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}


export default GridLayout;