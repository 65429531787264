import GlobalStyle from 'lib/GlobalStyle';

const getSpacerCSS = (elementId, content) => (`
  #code-${elementId} .cm-line:first-child:before {
    content: '${content}';
    padding: 2px 5px;
    display: block;
    background: tomato;
    height: 1em;
    float: right;
    opacity: 0;
  }`);

export default function TopLineSpacer({ elementId, content }) {
  return (<GlobalStyle css={getSpacerCSS(elementId, content)} />)        
}