import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { useControllerState } from 'lib/BaseController';
import { Button } from 'react-aria-components';
import { GridList, GridListItem, DropIndicator } from 'react-aria-components';
import { useDragAndDrop } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';

import { Cross2Icon } from '@radix-ui/react-icons';


export default function Titles({ editor }) {
  const tabbed = false;
  return (
    tabbed ?
      <TabbedTitles editor={editor} /> : 
      <OneTitle editor={editor} />
  );
}

export function OneTitle({ editor }) {
  const
    currentId = editor.state.get('currentId'),
    article = editor.articleControllers[currentId],
    title = article && article.state.getIn(['article', 'title']);

  return (
    <div className="flex-grow flex overflow-hidden items-center text-xl">
      {typeof title === 'string' && (
        <TitleInput article={article} key={article.id} />
      )}
      { typeof title !== 'string' && '' }
    </div>)
}

export function TitleInput({ article }) {
  const
    mRef = useRef(null),
    [focused, setFocused] = useState(false),
    value = article.state.getIn(['article', 'title']),
    [inputWidth, setInputWidth] = useState('auto');

  useEffect(() => {
    const textWidth = mRef.current.scrollWidth;
    setInputWidth(`calc(${textWidth}px + 1rem)`);
  }, [value]);

  return (
    <div className={clsx('relative pl-2', focused ? 'bg-wax' : 'hover:bg-wax2')}>
      <div ref={mRef} className="absolute whitespace-pre pointer-events-none max-w-full opacity-0">
        {value || ' '}
      </div>

      <input
        type="text"
        value={value}
        onChange={e => {
          const title = e.currentTarget.value.replace(/\n/g, '');
          article.setInState(['article', 'title'], title);
        }}
        onFocus={() => setFocused(true)}
        onBlur={e => { article.saveArticleStateKey('title'); setFocused(false); }}
        spellCheck="false"
        style={{ width: inputWidth }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            e.target.blur();
          }
        }}
        className="outline-none min-w-20 max-w-full bg-transparent" />
    </div>
  );
}


export function TabbedTitles({ editor }) {
  const
    state = useControllerState(editor),
    items = state.get('articleIds').map(i => editor.articleControllers[i]);

  let { dragAndDropHooks } = useDragAndDrop({
    getItems: (keys) => [...keys].map(key => ({ 'text/plain': key })),
    onReorder(e) {
      let
        sourceIndex = items.findIndex(i => i.id === Array.from(e.keys)[0]),
        destIndex = items.findIndex(i => i.id === e.target.key);
      if (e.target.dropPosition === 'after') {
        // destIndex -= 1;
      }
      if (sourceIndex !== destIndex) {
        editor.moveArticleId(sourceIndex, destIndex);
      }
    },
    renderDropIndicator(target) {
      return (
        <DropIndicator
          target={target}
          className={({ isDropTarget }) => `pr-1 bg-marker`}
        />
      );
    }
  })


  return (
    <div className="flex-grow flex overflow-auto border-l no-scrollbar">
      <GridList
        aria-label="Folder Contents"
        dragAndDropHooks={dragAndDropHooks}
        items={items}
        className="flex">
        {(articleController) => (
          <TitleItem
            articleController={articleController}
            editor={editor} />)}
      </GridList>
      <div className="flex-grow border-b"></div>
    </div>
  )
}

export function TitleItem({ articleController, editor }) {
  const
    state = useControllerState(articleController),
    { id } = articleController,
    title = state.getIn(['article', 'title']),
    path = state.getIn(['article', 'path']),
    editorState = useControllerState(editor),
    isActive = editorState.get('currentId') === id,
    // index = editorState.get('articleIds').indexOf(id),
    noCloseButton = editorState.get('articleIds').size === 1,
    navigate = useNavigate();
  

  return (
    <GridListItem
      id={id}
      key={id}
      className={({ isSelected }) => clsx(
        'border-r pl-6 pr-2 outline-none cursor-pointer whitespace-nowrap flex items-center gap-1 border-b group/doc-title',
        isActive ? 'font-medium' : 'bg-wax text-pencil3',
      )}
      href={'/editor/' + path}
      textValue={title || 'loading'}>
      <div className="truncate">{title}</div>
      <Button slot="drag" />
      <Button
        className={clsx(
          'hover:bg-wax2 opacity-0 rounded-full p-1',
          noCloseButton ? '' : 'group-hover/doc-title:opacity-100'
        )}
        onPress={() => { editor.closeArticle(id, navigate) }}
        isDisabled={noCloseButton}>
        <Cross2Icon />
      </Button>
    </GridListItem>
  )
}