import elementLibs from 'modules/elements/all';
import { useControllerState } from 'lib/BaseController';
import clsx from 'clsx';


export default function Scroll({ article, panelLeft, panelRight }) {
  const { layout } = article;
    
  return (
    <div
      className={clsx(
        'scroll-container px-12 pt-20 pb-24 transition-[padding]',
        panelLeft && 'md:pl-[22rem]',
        panelRight === 'detail' && 'md:pr-[42rem]',
        panelRight === 'comments' && 'md:pr-[27rem]'
      )}>
      <div className="scroll-page page-max-width mx-auto">
        <div className="my-6 article-title font-header">
          {article.article.title}
        </div>
        <div>
          { layout.order.map((id, index) => article.elementControllers[id] && (
            <Element key={id} element={article.elementControllers[id]} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Element({ element }) {
  useControllerState(element);
  const lib = elementLibs[element.type];
  return (
    <div className="my-8">
      <lib.Viewer controller={element.controller} />
    </div>
  )
}