import {Tabs, TabList, Tab, TabPanel} from 'react-aria-components';
import clsx from 'clsx';

function MyTabs({ tabs, selectedKey, onSelectionChange }) {
  return (
    <Tabs
      items={tabs}
      selectedKey={selectedKey}
      onSelectionChange={onSelectionChange}
      keyboardActivation="manual"
      className="h-full flex flex-col overflow-hidden">
      <TabList className="flex gap-5 px-3 pb-2 border-b border-rim">
        {tabs.map(({ key, caption }) => (
          <Tab
            key={key}
            id={key}
            className={({ isSelected, isDisabled }) => clsx(
              'text-sm outline-none cursor-pointer font-medium py-1',
              isSelected ? 'text-marker3 border-b-2 border-marker2' : 'text-pencil3 hover:text-pencil2'
            )}
          >{caption}</Tab>))}
      </TabList>

      {tabs.map(({ key, el }) => (
        <TabPanel key={key} id={key} className="flex-grow overflow-auto py-1">
          {el}
        </TabPanel>
      ))}
    </Tabs>

  )
}

export default MyTabs;