import { useState } from 'react';
import { useControllerState } from 'lib/BaseController';
import { Button } from 'react-aria-components';
import { PlayIcon, DotsVerticalIcon } from '@radix-ui/react-icons';
import { HeightResizer, WidthResizer } from 'modules/elements/lib/components/resizers';
import { Menu } from './menu';

import { TargetButton, RefreshButton } from './bars';

import clsx from 'clsx';


export function Floaters({ controller }) {
  const
    target = controller.getSettingsValue('target'),
    state = useControllerState(controller.webFrame),
    build = state.get('build');

  return (
    <>
      { !target ? (
        <NoTargetButton controller={controller} />
      ) : !build ? (
        <PlayButton controller={controller} />
      ) : '' }
    </>
  );
}

export function PlayButton({ controller }) {
  return (
    <Button
      className={clsx(
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
        'p-2 bg-paper rounded bg-opacity-50'
      )}
      onPress={() => {
        controller.run();
        controller.activate();
      }}>
      <PlayIcon className="w-10 h-10" />
    </Button>
  )
}


export function NoTargetButton({ controller }) {
  return (
    <Button
      className="font-mono text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-pencil3"
      onPress={controller.editTarget}>
      Missing target file.<br />
      Click to add.
    </Button>
  )
}


export function FloatingMenubar({ controller }) {
  const
    gsv = controller.getSettingsValue,
    showTarget = gsv('showTarget'),
    floatTarget = gsv('floatTarget'),
    menuColor = gsv('menuColor'),
    [opened, setOpened] = useState();

  return (
    <div className={clsx(
      'absolute right-2 top-2 transition-opacity flex items-center gap-3',
      showTarget && floatTarget && (
        menuColor === 'dark' ? 'color-scheme-dark' :
          menuColor === 'light' ? 'color-scheme-light' : ''
      ),
      (showTarget && floatTarget) ?
        'bg-paper px-3 py-1 border border-rim text-pen' :
        clsx(
          !opened && 'opacity-0 group-hover/webrunner:opacity-100',
          'p-1'
        )
    )}>
      { showTarget && floatTarget && (
        <>
          <TargetButton controller={controller} />
          <RefreshButton controller={controller} />
        </>
      ) }
      { (!controller.isReadOnly || !gsv('hideMenuOnPublish')) && (
        <FloatingMenu
          controller={controller}
          onOpenChange={(b) => {
            setOpened(b);
            if (b)
              controller.activate();
          }} />
      )}
    </div>
  )
}

export function FloatingMenu({ controller, onOpenChange }) {
  return (
    <Menu
      controller={controller}
      onOpenChange={onOpenChange}
      className="py-1">
      <DotsVerticalIcon />
    </Menu>
  )
}

export function Resizers({ controller, isGrid, onDrag }) {
  const
    { getSettingsValue, setSettings, isReadOnly } = controller,
    gsv = getSettingsValue,
    isFixed = gsv('layout') === 'fixed',
    fixedWidth = gsv('fixedWidth'),
    fixedHeight = gsv('fixedHeight'),
    height = gsv('height');

  return !isGrid && !isReadOnly && (
    <>
      <HeightResizer
        onDrag={onDrag}
        onChange={(dy) => {
          let
            h = (isFixed ? fixedHeight : height) + dy,
            range = controller.settingsFields[(isFixed ? 'fixedHeight' : 'height')].range;
          h = Math.max(Math.min(h, range[1]), range[0]);
          setSettings(isFixed ? 'fixedHeight' : 'height', h);
        }} />

      { isFixed && (
        <WidthResizer
          onDrag={onDrag}
          onChange={(dx) => {
            let
              w = fixedWidth + dx,
              range = controller.settingsFields.fixedWidth.range;
            w = Math.max(Math.min(w, range[1]), range[0]);
            setSettings('fixedWidth', w);
          }} />
      ) }
    </>
  )
}