import WebFrameView from 'lib/webframe/View';
import clsx from 'clsx';

const borderClass = 'border border-rim p-[4px]';


export function FixedFrame({ controller, pre, floaters, post }) {
  const
    gsv = controller.getSettingsValue,
    fixedWidth = gsv('fixedWidth'),
    fixedHeight = gsv('fixedHeight'),
    alignment = gsv('alignment'),
    hasBorder = gsv('hasBorder');

  return (
    <div style={{ textAlign: alignment }} onClick={controller.activate} className="group/webrunner">
      <div className={clsx('inline-block', hasBorder && borderClass)}>
        <div style={{ width: fixedWidth + 'px' }} className="text-left relative">
          <WebFrameView
            controller={controller.webFrame}
            height={fixedHeight}
            autoHeight={false} />
          {floaters}
        </div>
      </div>
      <div
        style={{ width: (fixedWidth + (borderClass ? 10 : 0) + 'px') }}
        className="text-left inline-block">
        {post}
      </div>
    </div>
  )
}


export function DynamicFrame({ controller, pre, floaters, post }) {
  const
    gsv = controller.getSettingsValue,
    height = gsv('height'),
    autoHeight = gsv('autoHeight'),
    hasBorder = gsv('hasBorder');

  return (
    <div onClick={controller.activate} className="group/webrunner">
      {pre}
      <div className={clsx('relative', hasBorder && borderClass)}>        
        <WebFrameView
          controller={controller.webFrame}
          height={height}
          autoHeight={autoHeight} />
        {floaters}
      </div>
      {post}
    </div>
  )
}

export function GridFrame({ controller, pre, floaters, post }) {
  return (
    <div className="flex flex-col h-full group/webrunner" onClick={controller.activate}>
      {pre && <div>{pre}</div> }
      <div
        className={clsx(
          'relative flex-grow overflow-hidden',
          controller.getSettingsValue('hasBorder') && borderClass
        )}>
        <WebFrameView controller={controller.webFrame} isFullHeight={true}  />
        {floaters}
      </div>
      {post}
    </div>
  )
}