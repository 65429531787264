import { useEffect, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from '@codemirror/view';
import { indentUnit } from '@codemirror/language';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import allThemes from 'lib/codemirror/themes';


export default function CodeMirrorInstance({
  fileController, onCreateEditor, onFocus,
  isHidden, themeKey, height, readOnly, lineWrapping, hasLineNumbers
}) {
  const
    { state, setState } = fileController,
    extensions = [],
    languageExtension = loadLanguage(fileController.getSettingsValue('mode') || fileController.getSuggestedMode()),
    tabMode = fileController.getSettingsValue('tabMode') || fileController.getSuggestedTabMode();
  
  if (languageExtension)
    extensions.push(languageExtension)

  if (lineWrapping) {
    extensions.push(EditorView.lineWrapping)
  }

  if (tabMode === '4s') {
    extensions.push(indentUnit.of('    '))
  }
  
  // Updater crew
  const [, setCurrentState] = useState(fileController.state);
  useEffect(() => fileController.onStateChange(() => setCurrentState(fileController.state)), [fileController]);

  return (
    <div
      className="codemirror-instance flex-grow"
      style={{display: isHidden ? 'none' : 'block'}}>
      <CodeMirror
        value={state.get('content') || ''}
        onChange={(value, viewUpdate) => setState({ content: value })}
        extensions={extensions}
        theme={allThemes[themeKey]}
        height={height}
        indentWithTab={true}
        basicSetup={{
          lineNumbers: hasLineNumbers,
          highlightActiveLineGutter: false,
          highlightActiveLine: false,
          foldGutter: false,
        }}
        readOnly={readOnly}
        onFocus={onFocus}
        onCreateEditor={onCreateEditor} />
    </div>
  );
}