import { ReloadIcon, Pencil1Icon, PlayIcon, ChevronRightIcon, CheckIcon } from '@radix-ui/react-icons';
import MenuBuilder from 'lib/components/MenuBuilder';


export function getOptions(controller) {
  const
    { getSettingsValue, toggleSettings, webFrame, isReadOnly } = controller,
    frameState = webFrame.state,
    gsv = getSettingsValue,
    build = frameState.get('build'),
    hasNewLogs = frameState.get('hasNewLogs'),
    hasNewErrors = frameState.get('hasNewErrors'),
    showConsole = frameState.get('showConsole');

  const result = [
    [
      build ? 'Reload' : 'Run',
      build ? ReloadIcon : PlayIcon,
      () => controller.run(), { isDisabled: !gsv('target') }
    ],
    [ 
      showConsole ? 'Hide Console' : 'Show Console',
      ChevronRightIcon,
      () => controller.webFrame.toggleConsole(),
      { notification: hasNewErrors ? 'alert': hasNewLogs ? 'normal' : '' }],
  ];

  if (isReadOnly)
    return result;

  result.push(
    [],
    ['Edit Target and Runtime', Pencil1Icon, () => controller.editTarget()],
    [],
    ['Display Target', gsv('showTarget') ? CheckIcon : '', () => toggleSettings('showTarget')],
    [
      'Float Target',
      (gsv('showTarget') && gsv('floatTarget')) ? CheckIcon : '',
      () => toggleSettings('floatTarget'),
      { isDisabled: !gsv('showTarget') }],
    [],
    ['Border', gsv('hasBorder') ? CheckIcon : '', () => toggleSettings('hasBorder')]
  );

  if (gsv('layout') === 'dynamic')
    result.push(
      ['Auto Height', gsv('autoHeight') ? CheckIcon : '', () => toggleSettings('autoHeight')],
    )

  return result;
}

export function Menu({ controller, className, onOpenChange, children }) {
  return (
    <MenuBuilder
      tight={true}
      className={className}
      onOpenChange={onOpenChange}
      options={getOptions(controller)}
      children={children} />
  );
}