import BaseElementController from 'modules/elements/lib/BaseElementController';
import settingsFields, { defaultImportMap } from '../Policies/settingsFields';
import WebFrame from 'lib/webframe/Controller';

import processCode from 'lib/transpiler/processCode';
import processCSS from 'lib/transpiler/processCSS';

export default class Controller extends BaseElementController {
  settingsFields = settingsFields;
  constructor(args) {
    super(args);
    this.webFrame = new WebFrame({
      vani: args.vani,
      saveEditorData: data => this.setState({ editorData: data }),
      getEditorData: () => this.getState().get('editorData'),
      openPopup: this.openPopup,
      getRuntimeFile: this.getRuntimeFile
    });
  }

  get isFloating() {
    return this.getSettingsValue('menuPosition') === 'floating';
  }

  checkAutorun() {
    if (this.isReadOnly && (this.getSettingsValue('autorun') || !this.getSettingsValue('showRunReadMode')))
      this.run();    
  }

  async run() {
    const
      title = this.getSettingsValue('title'),
      target = this.getSettingsValue('target'),
      runtime = this.getSettingsValue('runtime'),
      renderOption = this.getSettingsValue('renderOption'),
      classToRender = this.getSettingsValue('classToRender'),
      importMap = Object.assign({}, defaultImportMap, this.getSettingsValue('importMap')),
      localPath = this.docPath,
      colorScheme = this.getColorScheme();

    this.webFrame.clear();
    this.webFrame.setBuild({
      title,
      target,
      runtime,
      renderOption,
      classToRender,
      importMap,
      localPath,
      colorScheme
    });
  }

  destroy() {
    super.destroy();
    this.webFrame.destroy();
  }

  openImportMapPopup = () => {
    this.openPopup('code-editor', {
      title: 'Import Map JSON',
      language: 'json',
      value: JSON.stringify(this.getSettingsValue('importMap'), null, 2),
      onSave: async (value) => {
        try {
          this.setSettings('importMap', JSON.parse(value));
        } catch (e) {
          throw new Error('The entered JSON value is invalid.')
        }
      }
    });
  }

  // target file
  // Runtiime Pure / Standard
  // 

  editTarget = _ => this.openPopup('prompt', {
    title: 'Runtime Settings',
    inputs: [{
      label: 'Runtime',
      type: 'select2',
      options: settingsFields.runtime.options,
      defaultValue: this.getSettingsValue('runtime') || '',
    }, {
      label: 'Target JS Filename',
      type: 'text',
      defaultValue: this.getSettingsValue('target') || '',
      autoFocus: true
    }, {
      label: 'Render on #root',
      type: 'select2',
      options: [
        { key: 'none', caption: 'None' },
        { key: 'default', caption: 'Default exported component' },
        { key: 'export', caption: 'Exported component class' }
      ],
      isHiddenFn: ({ inputValues: [runtime] }) => runtime !== 'standard',
      defaultValue: this.getSettingsValue('renderOption') || false
    }, {
      label: 'Exported Component to Render',
      type: 'text',
      defaultValue: this.getSettingsValue('classToRender') || '',
      isHiddenFn: ({ inputValues: [runtime, ,renderOption] }) => runtime !== 'standard' || renderOption !== 'export',
    }],

    submitLabel: 'Save',

    onSubmit: ([runtime, target, renderOption, classToRender, title]) => {
      this.setSettings('runtime', runtime);
      this.setSettings('target', target);
      this.setSettings('renderOption', renderOption);
      this.setSettings('classToRender', classToRender);
    }
  })

  getRuntimeFile = async (pathname, destination) => {
    let
      docPath, file,
      localPath = this.docPath;

    if (pathname.startsWith(`/${localPath}/`)) {
      docPath = localPath;
      const filename = pathname.substr(docPath.length + 2);
      file = await this.getLocalFiles().getFile(filename);
    } else {
      file = await this.getRemoteFiles().getFile(pathname);
      docPath = file.docPath;
    }

    if (!file)
      throw new Error(`File not found "${pathname}" for "${destination}"`);
 
    if (destination === 'script' && this.getSettingsValue('runtime') !== 'pure' && file.isDocFile) {
      if (file.isScript) {
        const { fetchPath } = this.getRemoteFiles();
        return await processCode({ file, docPath, localPath, fetchPath });
      }

      if (file.isCSS) {
        return await processCSS({ file });
      }
    }
    return await file.toObject();
  }
}