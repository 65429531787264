import { useEffect, useState } from 'react';
import { DARK_THEME_KEYS } from 'modules/theme/defaults/code';
import CodeMirrorInstance from './CodeMirrorInstance';
import CollapsedView from './CollapsedView';
import IDELike from './IDELike';
import { Switch } from './layouts';

import './style.css';


export default function Editor({ controller, isActive, isGrid, isDetail, colorScheme }) {
  const
    fileControllers = controller.getOrderedFileControllers(),
    gsv = controller.getSettingsValue,
    lineWrapping = gsv('lineWrapping'),
    hasLineNumbers = gsv('hasLineNumbers'),
    isFullHeight = isGrid || isDetail,
    height = isFullHeight ? '100%' : gsv('autoHeight') ? null : `${gsv('height')}px`,
    readOnly = controller.isReadOnly && gsv('isReadOnly'),
    { size } = fileControllers,
    layout = (isDetail && 'detail') || gsv('layout') || (size === 1 ? 'float' : size <= 3 ? 'top' : 'left'),
    themeKey = gsv('theme') || controller.themeData.getValue(
      controller.getColorScheme() === 'dark' ? 'codeThemeDark' : 'codeThemeLight'
    ),
    isDark = DARK_THEME_KEYS.has(themeKey);

  const [, setChildren] = useState(controller.getChildren());
  useEffect(() => controller.onChildren(() => setChildren(controller.getChildren())), [controller]);

  if (gsv('isHiddenOnPublish') && controller.isReadOnly)
    return '';

  if (gsv('layout') === 'collapsed' && !isDetail)
    return (<CollapsedView {...{controller, fileControllers}} />);

  const children = fileControllers.map((fileController, index) => (
    <CodeMirrorInstance
      key={fileController.elementId}
      fileController={fileController}
      isHidden={controller.currentIndex !== index}
      onCreateEditor={editorView => controller.setEditor(editorView, fileController.elementId)}
      onFocus={controller.activate}
      {...{ themeKey, height, readOnly, lineWrapping, hasLineNumbers }} />
  ));

  const props = { isGrid, isDark, isActive, isFullHeight, controller, fileControllers, layout, children }
  
  if (isDetail)
    return (<IDELike {...props} />)

  return (<Switch {...props} />);
}